import { useContext } from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { parseDateUs } from 'services/utils';
import Button from 'uikit/Button/Button';
import './CardDetailsTableRow.scss';

const CARD_ACTIONS = {
  REPLACE: 'replace',
  BLOCK: 'block',
  UNBLOCK: 'unblock'
};

const CARD_ACTIONS_ARRAY = Object.values(CARD_ACTIONS);

export const CardDetailsTableRow = ({
  isLoading,
  cardDetailsData,
  blockCustomerCard,
  unblockCustomerCard,
  replaceCustomerCard
}) => {
  const i18n = useContext(i18nContext);

  const handleCardAction = (action) => {
    switch (action) {
      case CARD_ACTIONS.REPLACE:
        replaceCustomerCard(cardDetailsData.card_id);
        break;
      case CARD_ACTIONS.BLOCK:
        blockCustomerCard(cardDetailsData.card_id);
        break;
      case CARD_ACTIONS.UNBLOCK:
        unblockCustomerCard(cardDetailsData.card_id);
        break;
      default:
        break;
    }
  };

  const getCardInfoBlock = (cardBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as='p' animation='glow'>
          <Placeholder className={'card-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{cardBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'card-table-cell'}>{getCardInfoBlock(cardDetailsData.card_id)}</td>
      <td className={'card-table-cell'}>{getCardInfoBlock(cardDetailsData.card_number)}</td>
      <td className={'card-table-cell'}>{getCardInfoBlock(parseDateUs(cardDetailsData.created_at))}</td>
      <td className={'card-table-cell'}>
        {cardDetailsData.blocked_at && getCardInfoBlock(parseDateUs(cardDetailsData.blocked_at))}
      </td>
      <td className={'card-table-cell'}>
        {cardDetailsData.block_type &&
          getCardInfoBlock(i18n.getMessage(`card.blockType.${cardDetailsData.block_type}`))}
      </td>
      <td className={'card-table-cell'}>
        {getCardInfoBlock(i18n.getMessage(`card.status.${cardDetailsData.status}`))}
      </td>
      <td className={'card-table-cell'}>{getCardInfoBlock(cardDetailsData.wallet_number)}</td>
      <td className={'card-table-cell'}>
        <div className={'card-table-cell-actions'}>
          {CARD_ACTIONS_ARRAY.map((action) => (
            <Button key={action} onClick={() => handleCardAction(action)} type={'primary'} size={'small'} fullWidth>
              {i18n.getMessage(`cardDetails.table.actions.${action}`)}
            </Button>
          ))}
        </div>
      </td>
    </tr>
  );
};

CardDetailsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  cardDetailsData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    name: PropTypes.string,
    card_id: PropTypes.string.isRequired,
    card_number: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    blocked_at: PropTypes.string,
    wallet_number: PropTypes.string.isRequired,
    block_type: PropTypes.string
  }),
  blockCustomerCard: PropTypes.func,
  unblockCustomerCard: PropTypes.func,
  replaceCustomerCard: PropTypes.func
};
