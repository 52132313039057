import './index.css';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import accountsStore from 'stores/accountsStore';
import authStore from 'stores/authStore';
import cardsStore from 'stores/cardsStore';
import customersStore from 'stores/customersStore';
import messagesStore from 'stores/messagesStore';
import paymentObligationsStore from 'stores/paymentObligationsStore';
import transactionsStore from 'stores/transactionsStore';
import usersStore from 'stores/usersStore';
import ApplicationProvider from 'contexts/ApplicationContext';

import App from './App';
import 'assets/fonts/Manrope-Regular.ttf';
import 'assets/fonts/Manrope-SemiBold.ttf';
import 'bootstrap/dist/css/bootstrap.css';
import 'styles.scss';

const stores = {
  accountsStore,
  authStore,
  cardsStore,
  customersStore,
  messagesStore,
  paymentObligationsStore,
  transactionsStore,
  usersStore
};

ReactDOM.render(
  <StrictMode>
    <Provider {...stores}>
      <ApplicationProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApplicationProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);
