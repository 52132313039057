import { Fragment, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { IS_TRANSFER_TYPE_AVAILABLE } from 'config/config';
import { convertUTCtoGBOnlyDate, formatMoney } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import './TransactionsTableRow.scss';

const TransactionsTableRow = ({ isLoading, transactionData }) => {
  const i18n = useContext(i18nContext);

  const getTransactionInfoBlock = (transactionBlockInfo, isTransactionId = false) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'transactions-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isTransactionId) {
      return (
        <NavLink className={'transaction-id-text'} to={`${ROUTE_PATHS.TRANSACTIONS}/${transactionBlockInfo}`}>
          {transactionBlockInfo}
        </NavLink>
      );
    } else if (Array.isArray(transactionBlockInfo)) {
      return (
        <p>
          {transactionBlockInfo.map((item, index) => {
            if (item) {
              return (
                <Fragment key={index}>
                  {item}
                  {index < transactionBlockInfo.length - 1 && <br />}
                </Fragment>
              );
            }
          })}
        </p>
      );
    } else {
      return <p>{transactionBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'transactions-table-cell'}>{getTransactionInfoBlock(transactionData.transactionNumber, true)}</td>
      <td className={'transactions-table-cell'}>
        {getTransactionInfoBlock(convertUTCtoGBOnlyDate(transactionData.createdAt))}
      </td>
      <td className={'transactions-table-cell'}>
        {getTransactionInfoBlock(convertUTCtoGBOnlyDate(transactionData.updatedAt))}
      </td>
      {IS_TRANSFER_TYPE_AVAILABLE && (
        <td className={'transactions-table-cell'}>
          {getTransactionInfoBlock(i18n.getMessage(`paymentProvider.${transactionData.transferProvider}`))}
        </td>
      )}
      <td className={'transactions-table-cell'}>
        {getTransactionInfoBlock(i18n.getMessage(`transferType.${transactionData.transferType}`))}
      </td>
      <td className={'transactions-table-cell'}>
        {getTransactionInfoBlock(i18n.getMessage(`transactionType.${transactionData.transactionType}`))}
      </td>
      <td className={'transactions-table-cell'}>
        {getTransactionInfoBlock(i18n.getMessage(`paymentMethod.${transactionData.paymentMethod}`))}
      </td>
      <td className={'transactions-table-cell'}>
        {getTransactionInfoBlock(i18n.getMessage(`transactionStatus.${transactionData.status}`))}
      </td>
      <td className={'transactions-table-cell'}>
        {getTransactionInfoBlock(formatMoney(transactionData.sourceAmount, transactionData.currency, 2))}
      </td>
      <td className={'transactions-table-cell'}>{getTransactionInfoBlock(transactionData.currency)}</td>
      <td className={'transactions-table-cell'}>
        {getTransactionInfoBlock(formatMoney(transactionData.commission, transactionData.currency, 2))}
      </td>
      <td className={'transactions-table-cell'}>{getTransactionInfoBlock(transactionData.recipient?.name)}</td>
      <td className={'transactions-table-cell'}>{getTransactionInfoBlock(transactionData.recipient?.bank)}</td>
      <td className={'transactions-table-cell'}>{getTransactionInfoBlock(transactionData.recipient?.bankCountry)}</td>
      <td className={'transactions-table-cell'}>{getTransactionInfoBlock(transactionData.sender?.name)}</td>
      <td className={'transactions-table-cell'}>{getTransactionInfoBlock(transactionData.sender?.bank)}</td>
      <td className={'transactions-table-cell'}>{getTransactionInfoBlock(transactionData.sender?.bankCountry)}</td>
      <td className={'transactions-table-cell'}>{getTransactionInfoBlock(transactionData.comment)}</td>
      <td className={'transactions-table-cell'}>
        {getTransactionInfoBlock(
          transactionData?.hasDocuments
            ? i18n.getMessage('transactions.table.rowBlock.documents.yes')
            : i18n.getMessage('transactions.table.rowBlock.documents.no')
        )}
      </td>
      <td className={'transactions-table-cell'}>{getTransactionInfoBlock(transactionData.externalReference)}</td>
    </tr>
  );
};

TransactionsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  transactionData: PropTypes.object
};

export default TransactionsTableRow;
