import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import i18nContext from 'components/i18n-context';
import { CURRENCY, PAYMENT_OBLIGATION_STATUS } from 'components/constants';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './PaymentObligationsFiltersBar.scss';

const PaymentObligationsFiltersBar = ({ filters, handleSearchText, handleSetFilters }) => {
  const i18n = useContext(i18nContext);

  const paymentObligationsStatusesOptions = Object.keys(PAYMENT_OBLIGATION_STATUS).map((status) => {
    return {
      key: PAYMENT_OBLIGATION_STATUS[status],
      value: i18n.getMessage('paymentObligationStatus.' + PAYMENT_OBLIGATION_STATUS[status])
    };
  });

  const currencyOptions = Object.keys(CURRENCY).map((status) => {
    return {
      key: CURRENCY[status],
      value: CURRENCY[status]
    };
  });

  return (
    <div className={'payment-obligations-filters-wrapper'}>
      <div className={'payment-obligations-date-range-filters-wrapper'}>
        <div>
          <span>{i18n.getMessage('paymentObligations.filter.from.label')}</span>
          <CustomDatePicker onChange={handleSetFilters} name={'from_date'} value={filters.from_date} />
        </div>
        <div>
          <span>{i18n.getMessage('paymentObligations.filter.to.label')}</span>
          <CustomDatePicker onChange={handleSetFilters} name={'to_date'} value={filters.to_date} />
        </div>
      </div>

      <InputDropDown
        className={'payment-obligations-filter-wrapper'}
        label={i18n.getMessage('paymentObligations.filter.status.label')}
        name={'statuses'}
        value={filters.statuses}
        options={paymentObligationsStatusesOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'payment-obligations-filter-wrapper'}
        label={i18n.getMessage('paymentObligations.filter.currency.label')}
        name={'currencies'}
        value={filters.currencies}
        options={currencyOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <Input
        className={'payment-obligations-filter-wrapper'}
        label={i18n.getMessage('paymentObligations.filter.search.label')}
        placeholder={i18n.getMessage('paymentObligations.filter.search.placeholder')}
        name={'search_text'}
        value={filters.search_text}
        onChange={handleSearchText}
        Icon={SearchIcon}
      />
    </div>
  );
};

PaymentObligationsFiltersBar.propTypes = {
  filters: PropTypes.object,
  handleSetFilters: PropTypes.func,
  handleSearchText: PropTypes.func
};

export default PaymentObligationsFiltersBar;
