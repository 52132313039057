import { useContext, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import i18nContext from 'components/i18n-context';
import AuthorizationLayout from 'components/EntryLayouts/AuthorizationLayout';
import ApplicationLayout from 'components/EntryLayouts/ApplicationLayout';
import { ROUTE_PATHS, UNAUTHORIZED_ROUTE_PATHS } from 'routes/constants';
import { ElementWrapper } from 'routes/ElementWrapper';
import LoginPage from 'pages/Login';
import CustomersPage from 'pages/Customers';
import CustomersTable from 'pages/Customers/components/CustomersTable';
import IndividualCustomerDetails from 'pages/Customers/components/IndividualCustomerDetails';
import CorporateCustomerDetails from 'pages/Customers/components/CorporateCustomerDetails';
import UsersPage from 'pages/Users';
import UsersTable from 'pages/Users/components/UsersTable';
import UserDetails from 'pages/Users/components/UserDetails';
import CreateUser from 'pages/Users/components/CreateUser';
import AccountsPage from 'pages/Accounts';
import AccountsTable from 'pages/Accounts/components/AccountsTable';
import SystemAccountsPage from 'pages/SystemAccounts';
import SystemAccountsTable from 'pages/SystemAccounts/components/SystemAccountsTable';
import TransactionsPage from 'pages/Transactions';
import TransactionsTable from 'pages/Transactions/components/TrasactionsTable';
import TransactionsDetails from 'pages/Transactions/components/TransactionDetails';
import PaymentObligationsPage from 'pages/PaymentObligations';
import PaymentObligationsTable from 'pages/PaymentObligations/components/PaymentObligationsTable';
import CardAuthorisationsPage from 'pages/CardAuthorisations';
import CardAuthorisationsTable from 'pages/CardAuthorisations/components/CardAuthorisationsTable';
import MessagesPage from 'pages/Messages';
import MessagesTopicsTable from 'pages/Messages/components/MessagesTopicsTable';
import NewMessage from 'pages/Messages/components/NewMessage';
import TopicMessages from 'pages/Messages/components/TopicMessages';
import { getAccessExpiresOn, isTokenValid } from './services/authUtils';
import { setTokenRefresh } from './services/requestAgent';

function App({ authStore }) {
  const navigate = useNavigate();
  const location = useLocation();
  const i18n = useContext(i18nContext);

  useEffect(() => {
    if (isTokenValid()) {
      const expiresOn = getAccessExpiresOn();
      setTokenRefresh((expiresOn - Date.now()) / 1000);
      if (!authStore.isLoginSuccess) {
        authStore.getUserRoles(true).then(() => {});
      }
    }

    // Need to do some logic (API) to check user authorization status
    if (
      !isTokenValid() &&
      !Object.keys(UNAUTHORIZED_ROUTE_PATHS).filter((path) =>
        location.pathname.includes(UNAUTHORIZED_ROUTE_PATHS[path])
      ).length
    ) {
      navigate(ROUTE_PATHS.LOGIN);
    }
  }, [navigate, location.pathname, authStore]);

  return (
    <Routes>
      <Route path={ROUTE_PATHS.LOGIN} element={<AuthorizationLayout />}>
        <Route index element={<ElementWrapper component={LoginPage} />} />
      </Route>
      <Route path={ROUTE_PATHS.ROOT} element={<ApplicationLayout />}>
        <Route path={ROUTE_PATHS.CUSTOMERS} element={<ElementWrapper component={CustomersPage} />}>
          <Route
            index
            element={<ElementWrapper component={CustomersTable} headerText={i18n.getMessage('container.customers')} />}
          />
          <Route
            path={ROUTE_PATHS.CUSTOMER_CORPORATE}
            element={
              <ElementWrapper
                component={CorporateCustomerDetails}
                headerText={i18n.getMessage('container.corporateCustomerDetails')}
              />
            }
          />
          <Route
            path={ROUTE_PATHS.CUSTOMER_INDIVIDUAL}
            element={
              <ElementWrapper
                component={IndividualCustomerDetails}
                headerText={i18n.getMessage('container.individualCustomerDetails')}
              />
            }
          />
        </Route>
        <Route path={ROUTE_PATHS.ACTIVE_CUSTOMERS} element={<ElementWrapper component={CustomersPage} />}>
          <Route
            index
            element={
              <ElementWrapper component={CustomersTable} headerText={i18n.getMessage('container.activeCustomers')} />
            }
          />
          <Route
            path={ROUTE_PATHS.ACTIVE_CUSTOMER_CORPORATE}
            element={
              <ElementWrapper
                component={CorporateCustomerDetails}
                headerText={i18n.getMessage('container.corporateCustomerDetails')}
              />
            }
          />
          <Route
            path={ROUTE_PATHS.ACTIVE_CUSTOMER_INDIVIDUAL}
            element={
              <ElementWrapper
                component={IndividualCustomerDetails}
                headerText={i18n.getMessage('container.individualCustomerDetails')}
              />
            }
          />
        </Route>
        <Route path={ROUTE_PATHS.USERS} element={<ElementWrapper component={UsersPage} />}>
          <Route
            index
            element={<ElementWrapper component={UsersTable} headerText={i18n.getMessage('container.users')} />}
          />
          <Route
            path={ROUTE_PATHS.USER_DETAILS}
            element={<ElementWrapper component={UserDetails} headerText={i18n.getMessage('container.userDetails')} />}
          />
          <Route
            path={ROUTE_PATHS.USER_CREATE}
            element={<ElementWrapper component={CreateUser} headerText={i18n.getMessage('container.createUser')} />}
          />
        </Route>
        <Route path={ROUTE_PATHS.ACCOUNTS} element={<ElementWrapper component={AccountsPage} />}>
          <Route
            index
            element={<ElementWrapper component={AccountsTable} headerText={i18n.getMessage('container.accounts')} />}
          />
        </Route>
        <Route path={ROUTE_PATHS.SYSTEM_ACCOUNTS} element={<ElementWrapper component={SystemAccountsPage} />}>
          <Route
            index
            element={
              <ElementWrapper
                component={SystemAccountsTable}
                headerText={i18n.getMessage('container.systemAccounts')}
              />
            }
          />
        </Route>
        <Route path={ROUTE_PATHS.TRANSACTIONS} element={<ElementWrapper component={TransactionsPage} />}>
          <Route
            index
            element={
              <ElementWrapper component={TransactionsTable} headerText={i18n.getMessage('container.transactions')} />
            }
          />
          <Route
            path={ROUTE_PATHS.TRANSACTION_DETAILS}
            element={
              <ElementWrapper
                component={TransactionsDetails}
                headerText={i18n.getMessage('container.transactionDetails')}
              />
            }
          />
        </Route>
        <Route path={ROUTE_PATHS.PAYMENT_OBLIGATIONS} element={<ElementWrapper component={PaymentObligationsPage} />}>
          <Route
            index
            element={
              <ElementWrapper
                component={PaymentObligationsTable}
                headerText={i18n.getMessage('container.paymentObligations')}
              />
            }
          />
        </Route>
        <Route path={ROUTE_PATHS.CARD_AUTHORIZATIONS} element={<ElementWrapper component={CardAuthorisationsPage} />}>
          <Route
            index
            element={
              <ElementWrapper
                component={CardAuthorisationsTable}
                headerText={i18n.getMessage('container.cardAuthorisations')}
              />
            }
          />
        </Route>
        <Route path={ROUTE_PATHS.MESSAGES} element={<ElementWrapper component={MessagesPage} />}>
          <Route
            index
            element={
              <ElementWrapper component={MessagesTopicsTable} headerText={i18n.getMessage('container.messages')} />
            }
          />
          <Route
            path={ROUTE_PATHS.NEW_MESSAGE}
            element={<ElementWrapper component={NewMessage} headerText={i18n.getMessage('container.newMessage')} />}
          />
          <Route
            path={ROUTE_PATHS.TOPIC_MESSAGES}
            element={<ElementWrapper component={TopicMessages} headerText={i18n.getMessage('container.messages')} />}
          />
        </Route>
      </Route>
    </Routes>
  );
}

App.propTypes = {
  authStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  authStore: stores.authStore
}))(observer(App));
