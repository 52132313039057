import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import queryString from 'query-string';
import { UsersHeadRow } from './components/UsersHeadRow';
import { UsersTableRow } from './components/UsersTableRow';
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import i18nContext from 'components/i18n-context';
import { DIRECTION, MENU_DIRECTION, USER_STATUS } from 'components/constants';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './UsersTable.scss';

const UsersTable = ({ usersStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = (filters, pagination) => {
    const queryParams = {
      page: pagination.page,
      size: pagination.size,
      sort_column: pagination.sortBy,
      sort_direction: pagination.direction
    };

    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        queryParams[key] = value;
      }
    });

    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    usersStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!usersStore.isInitialized) {
      usersStore.getUsersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersStore.isInitialized]);

  useEffect(() => {
    if (usersStore.isInitialized) {
      usersStore.resetUsersStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(usersStore.filters, usersStore.pagination);
    if (usersStore.isInitialized) {
      usersStore.getUsersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    usersStore.filters.userStatuses,
    usersStore.filters.searchText,
    usersStore.pagination.sortBy,
    usersStore.pagination.direction,
    usersStore.pagination.size,
    usersStore.pagination.page
  ]);

  const handleSetCurrentPage = (pageNumber) => {
    usersStore.setUsersPage(pageNumber);
  };

  const handleSetPageSize = (size) => {
    usersStore.setUsersPageSize(size);
  };

  const statusOptions = Object.keys(USER_STATUS).map((status) => {
    return {
      key: USER_STATUS[status],
      value: i18n.getMessage('users.status.' + USER_STATUS[status])
    };
  });

  const handleSetFilters = (name, value) => {
    usersStore.setFilter(name, value);
  };

  const handleSearchText = ({ target: { name, value } }) => {
    usersStore.setFilter(name, value);
  };

  const handleOnSortTable = (newSortBy) => {
    usersStore.setSortData({
      sortBy: newSortBy,
      direction:
        usersStore.pagination.sortBy !== newSortBy
          ? DIRECTION.ASC
          : usersStore.pagination.direction === DIRECTION.ASC
          ? DIRECTION.DESC
          : DIRECTION.ASC
    });
  };

  if (!usersStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'users-container'} header={i18n.getMessage('container.users')}>
      <div className={'users-actions-wrapper'}>
        <div className={'users-filters-inputs'}>
          <InputDropDown
            className={'filters-wrapper'}
            label={i18n.getMessage('users.filter.userStatus')}
            name={'userStatuses'}
            value={usersStore.filters.userStatuses}
            options={statusOptions}
            onChange={handleSetFilters}
            isMulti
          />
          <Input
            className={'filters-wrapper'}
            name={'searchText'}
            label={i18n.getMessage('users.filter.search.label')}
            placeholder={i18n.getMessage('users.filter.search.placeholder')}
            value={usersStore.filters.searchText}
            onChange={handleSearchText}
            Icon={SearchIcon}
          />
        </div>

        <Button
          className={'users-button'}
          size={'medium'}
          type={'primary'}
          onClick={usersStore.handleExportUsers}
          isDisabled={!usersStore.isInitialized || usersStore.isLoadingUsersCsv}
        >
          {usersStore.isLoadingUsersCsv ? <Loader /> : i18n.getMessage('users.button.usersCsv')}
        </Button>
      </div>

      <Pagination
        isShowPaginationSize={true}
        paginationSize={usersStore.pagination.size}
        handleChangePaginationSize={handleSetPageSize}
        isLoading={usersStore.isLoading}
        currentPage={usersStore.pagination.page}
        numberOfPages={usersStore.pagination.totalPages}
        setCurrentPage={handleSetCurrentPage}
      />

      <Table responsive>
        <thead>
          <UsersHeadRow
            handleOnSortTable={handleOnSortTable}
            sortBy={usersStore.pagination.sortBy}
            direction={usersStore.pagination.direction}
          />
        </thead>
        <tbody>
          {usersStore.users?.map((user, index) => (
            <UsersTableRow key={index} type={'list'} isLoading={usersStore.isLoading} userData={user} />
          ))}
        </tbody>
      </Table>

      <Pagination
        isShowPaginationSize={true}
        paginationSize={usersStore.pagination.size}
        handleChangePaginationSize={handleSetPageSize}
        isLoading={usersStore.isLoading}
        currentPage={usersStore.pagination.page}
        numberOfPages={usersStore.pagination.totalPages}
        setCurrentPage={handleSetCurrentPage}
        menuDirection={MENU_DIRECTION.UP}
      />
    </Container>
  );
};

UsersTable.propTypes = {
  usersStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  usersStore: stores.usersStore
}))(observer(UsersTable));
