import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import CustomersFiltersBar from './components/CustomersFiltersBar';
import { CustomersHeadRow } from './components/CustomersHeadRow';
import { CustomersTableRow } from './components/CustomersTableRow';
import { ReactComponent as CreateUserIcon } from 'assets/iconButtons/users.svg';
import i18nContext from 'components/i18n-context';
import { DIRECTION, MENU_DIRECTION } from 'components/constants';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import './CustomersTable.scss';

const CustomersTable = ({ customersStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = (filters, pagination, sortColumn) => {
    const queryParams = {
      ...filters,
      page: pagination.page,
      size: pagination.size,
      sort_column: sortColumn.sort_column,
      sort_direction: sortColumn.sort_direction
    };
    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    customersStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!customersStore.isInitialized) {
      customersStore.requestInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersStore.isInitialized]);

  useEffect(() => {
    if (location.pathname === ROUTE_PATHS.ACTIVE_CUSTOMERS) {
      customersStore.setIsShowActiveCustomers(true);
    } else {
      customersStore.setIsShowActiveCustomers(false);
    }

    if (customersStore.isInitialized) {
      customersStore.resetCustomersStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(customersStore.filters, customersStore.pagination, customersStore.sortColumn);
    if (customersStore.isInitialized) {
      customersStore.getCustomersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customersStore.filters.account_statuses,
    customersStore.filters.kyc_statuses,
    customersStore.filters.risk_profiles,
    customersStore.filters.types,
    customersStore.filters.tariff_groups,
    customersStore.filters.limit_groups,
    customersStore.filters.search_text,
    customersStore.pagination.size,
    customersStore.pagination.page,
    customersStore.sortColumn.sort_column,
    customersStore.sortColumn.sort_direction
  ]);

  const handleSetCurrentPage = (pageNumber) => {
    customersStore.setPageNumber(pageNumber);
  };

  const handleSetPageSize = (size) => {
    customersStore.setPageSize(size);
  };

  const handleSetFilters = (name, value) => {
    customersStore.setFilter(name, value);
  };

  const handleSearchText = ({ target: { name, value } }) => {
    customersStore.setFilter(name, value);
  };

  const handleOnSortTable = (newSortBy) => {
    customersStore.setSortData({
      sortBy: newSortBy,
      direction:
        customersStore.sortColumn.sort_column !== newSortBy
          ? DIRECTION.ASC
          : customersStore.sortColumn.sort_direction === DIRECTION.ASC
          ? DIRECTION.DESC
          : DIRECTION.ASC
    });
  };

  const handleCreateUserButtonClick = () => {
    navigate(ROUTE_PATHS.USER_CREATE);
  };

  if (!customersStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container
      className={'users-container'}
      header={i18n.getMessage(
        `${location.pathname === ROUTE_PATHS.ACTIVE_CUSTOMERS ? 'container.activeCustomers' : 'container.customers'}`
      )}
    >
      <div className={'customers-actions-wrapper'}>
        <Button className={'customers-button'} size={'medium'} type={'primary'} onClick={handleCreateUserButtonClick}>
          <CreateUserIcon />
          {i18n.getMessage('customers.button.createUser')}
        </Button>

        <div className={'export-csv-reports-wrapper'}>
          <Button
            className={'customers-button'}
            size={'medium'}
            type={'primary'}
            onClick={customersStore.handleExportCustomers}
            isDisabled={!customersStore.isInitialized || customersStore.isLoadingCustomerCsv}
          >
            {customersStore.isLoadingCustomerCsv ? <Loader /> : i18n.getMessage('customers.button.customersCsv')}
          </Button>
          <Button
            className={'customers-button'}
            size={'medium'}
            type={'primary'}
            onClick={customersStore.handleExportBalances}
            isDisabled={!customersStore.isInitialized || customersStore.isLoadingBalancesCsv}
          >
            {customersStore.isLoadingBalancesCsv ? <Loader /> : i18n.getMessage('customers.button.balancesCsv')}
          </Button>
        </div>
      </div>

      <CustomersFiltersBar
        isShowActiveCustomers={customersStore.isShowActiveCustomers}
        filters={customersStore.filters}
        limits={customersStore.limits}
        tariffs={customersStore.tariffs}
        handleSetFilters={handleSetFilters}
        handleSearchText={handleSearchText}
      />

      <Pagination
        isShowPaginationSize={true}
        paginationSize={customersStore.pagination.size}
        handleChangePaginationSize={handleSetPageSize}
        isLoading={customersStore.isLoading}
        currentPage={customersStore.pagination.page}
        numberOfPages={customersStore.pagination.totalPages}
        setCurrentPage={handleSetCurrentPage}
      />

      <Table responsive>
        <thead>
          <CustomersHeadRow
            handleOnSortTable={handleOnSortTable}
            sortBy={customersStore.sortColumn.sort_column}
            direction={customersStore.sortColumn.sort_direction}
          />
        </thead>
        <tbody>
          {customersStore.customers?.map((customer, index) => (
            <CustomersTableRow key={index} type={'list'} isLoading={customersStore.isLoading} customerData={customer} />
          ))}
        </tbody>
      </Table>

      <Pagination
        isShowPaginationSize={true}
        paginationSize={customersStore.pagination.size}
        handleChangePaginationSize={handleSetPageSize}
        isLoading={customersStore.isLoading}
        currentPage={customersStore.pagination.page}
        numberOfPages={customersStore.pagination.totalPages}
        setCurrentPage={handleSetCurrentPage}
        menuDirection={MENU_DIRECTION.UP}
      />
    </Container>
  );
};

CustomersTable.propTypes = {
  customersStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  customersStore: stores.customersStore
}))(observer(CustomersTable));
