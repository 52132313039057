import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CalendarIcon } from 'assets/calendar-icon.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomDatePicker.scss';

export const CustomDatePicker = ({ className, name, onChange, value, isDisabled, error }) => {
  const pickDate = (date) => {
    onChange(name, date);
  };

  return (
    <div className={`datepicker ${className}`}>
      <div className={'datepicker-block'}>
        <DatePicker
          showMonthDropdown={true}
          className={'date-input'}
          disabled={isDisabled}
          dateFormat={'dd/MM/yyyy'}
          name={name}
          id={name}
          placeholderText={'dd/mm/yyyy'}
          selected={value}
          onChange={(date) => pickDate(date)}
        />
        <CalendarIcon className={'date-icon'} />
      </div>
      {error && <p className={'date-picker-error'}>{error}</p>}
    </div>
  );
};

CustomDatePicker.propTypes = {
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};
