import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { CARD_BLOCK_TYPE } from 'components/constants';
import Button from 'uikit/Button/Button';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './PopUpBlockCardScheme.scss';
import Loader from '../../../Loader';

const PopUpBlockCardScheme = ({ isLoading, onClose, onConfirm }) => {
  const i18n = useContext(i18nContext);
  const [blockType, setBlockType] = useState('');

  const blockTypeOptions = Object.values(CARD_BLOCK_TYPE).map((value) => ({
    key: value,
    value: i18n.getMessage(`card.blockType.${value}`)
  }));

  const handleChangeCardBlockType = (name, value) => {
    setBlockType(value);
  };

  const handleBlockCard = () => {
    onConfirm(blockType);
  };

  return (
    <div className={'scheme-wrapper card-block-scheme-wrapper'}>
      <h2 className={'scheme-success-header'}>{i18n.getMessage('cardDetails.modal.blockCard.header')}</h2>
      <InputDropDown
        className={'table-cell-input'}
        label={i18n.getMessage('cardDetails.modal.blockCard.selector.label')}
        options={blockTypeOptions}
        value={blockType}
        onChange={handleChangeCardBlockType}
      />
      <div className={'scheme-wrapper-buttons card-block-scheme-buttons-wrapper'}>
        <Button className={'scheme-button'} type={'outline'} onClick={onClose} isDisabled={isLoading}>
          {i18n.getMessage('cardDetails.modal.blockCard.button.back')}
        </Button>
        <Button className={'scheme-button'} onClick={handleBlockCard} isDisabled={!blockType || isLoading}>
          {isLoading ? <Loader /> : i18n.getMessage('cardDetails.modal.blockCard.button.block')}
        </Button>
      </div>
    </div>
  );
};

PopUpBlockCardScheme.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default PopUpBlockCardScheme;
