import { CUSTOMER_STATUSES, CUSTOMER_TYPE, TIMEZONE } from 'components/constants';
import numeral from 'numeral';
import moment from 'moment-timezone';
import i18n from 'i18n';

export const emailRegExp =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const cryptoCurrencies = ['ETH', 'FNMC'];

export const convertBytesToMegabytes = (bytes) => bytes / (1024 * 1024);

export const isCryptoCurrency = (currency) => cryptoCurrencies.includes(currency.toUpperCase());

export const getCustomerLink = (customerNumber, customerStatus, customerType) => {
  return `/${customerStatus === CUSTOMER_STATUSES.VERIFIED ? 'active-customers' : 'customers'}/${
    customerType === CUSTOMER_TYPE.INDIVIDUAL ? 'individual' : 'corporate'
  }/${customerNumber}`;
};

export const parseDateUs = (date) => {
  if (date) {
    return new Date(date).toLocaleDateString('en-US', {
      timeZone: TIMEZONE,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  return null;
};

const dateOpts = {
  /*timeZone: TIMEZONE,*/ // should be already formatted in backend
  year: 'numeric',
  month: 'short',
  day: '2-digit'
};

const timeOpts = {
  /*timeZone: TIMEZONE,*/ // should be already formatted in backend
  hour: '2-digit',
  minute: '2-digit'
};

export const parseDate = (data) => {
  const date = new Date(data);

  return {
    date: date.toLocaleString('en-UK', dateOpts),
    time: date.toLocaleString('en-UK', timeOpts) + ' GB'
  };
};

export const convertGBtoUTC = (gbDate) => {
  return moment.tz(gbDate, TIMEZONE).utc().format();
};

export const convertUTCtoGBOnlyDate = (utcDate) => {
  const momentUtcDate = moment.utc(utcDate);

  return moment.tz(momentUtcDate, TIMEZONE).format('MM/DD/YYYY');
};

export const convertUTCtoGB = (utcDate) => {
  const momentUtcDate = moment.utc(utcDate);
  const convertedGBDate = moment.tz(momentUtcDate, TIMEZONE);

  const date = moment(convertedGBDate).format('D MMM YYYY');
  const time = moment(convertedGBDate).format('HH:mm') + ' GB';

  return { date, time };
};

export const parseGBDateToString = (gbDate) => {
  if (!gbDate) {
    return gbDate;
  }

  return `${gbDate.date} ${gbDate.time}`;
};

export const convertDate = (date) => {
  if (date) {
    return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${
      date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    }`;
  }

  return date;
};

export const convertToFullDate = (dateStr) => {
  const [year, month, day] = dateStr.split('-').map(Number);

  return new Date(year, month - 1, day); // months are 0-indexed
};

export const dateToString = (dateStr, showLocale = true) => {
  if (!dateStr) {
    return dateStr;
  }

  const date = new Date(dateStr);
  return `${date.toLocaleString('en-UK', dateOpts)} ${date.toLocaleString('en-UK', timeOpts)} ${
    showLocale ? 'GB' : ''
  }`;
};

export const debounce = (func, delay = 400) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      return func.apply(context, args);
    }, delay);
  };
};

export const MEDIA_QUERY = {
  HEADER_BUTTON_NAMES: '(min-width: 1050px)',
  DESKTOP: '(min-width: 769px)',
  NON_DESKTOP: '(min-width: 320px)',
  MOBILE: '(max-width: 499px)'
};

export const amountFormattedValue = (value, precision = 2) => {
  if (value === undefined || value === '' || value === null) {
    return '';
  }

  let stringValue = value.toString();

  const dotIndex = stringValue.indexOf('.');

  if (dotIndex !== -1) {
    stringValue = stringValue.slice(0, dotIndex + precision + 1);
  }

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision
  }).format(Number(stringValue));
};

export const format = (val) => {
  const formatted = val?.split('_').join(' ').toLowerCase();
  const capitalized = formatted?.charAt(0).toUpperCase() + formatted?.slice(1);
  return capitalized;
};

export const formatNumber = (number, precision) => {
  if (number === undefined || number === '') {
    return '';
  }

  let fractionalSize = '';
  for (let i = 0; i < precision; i++) {
    fractionalSize += '0';
  }

  return numeral(number).format('0,00.' + fractionalSize);
};
export const formatMoney = (amount, currency = '', precision = 2) => {
  if (amount === undefined || amount === null) {
    return '';
  }

  if (!currency || !isCryptoCurrency(currency)) {
    return formatNumber(amount, precision);
  }
  return Number(amount).toFixed(10);
};

export const regularFieldRegExp = /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~\s]*$/;
export const countryCodeRegExp = /^[A-Z]+$/;

export const parseExternalKycState = (kycState) => {
  if (!kycState) {
    return {
      statuses: '',
      tags: ''
    };
  }

  const { tags, initiated_at, approved_at, rejected_at } = kycState;
  const statuses = [];
  let parsedTags = '';
  let parsedStatuses = '';

  if (initiated_at) {
    statuses.push(
      i18n.getMessage('customers.externalKycProvider.status.documentsRequested', {
        date: parseGBDateToString(convertUTCtoGB(initiated_at))
      })
    );
  }
  if (approved_at) {
    statuses.push(
      i18n.getMessage('customers.externalKycProvider.status.approved', {
        date: parseGBDateToString(convertUTCtoGB(approved_at))
      })
    );
  }
  if (rejected_at) {
    statuses.push(
      i18n.getMessage('customers.externalKycProvider.status.rejected', {
        date: parseGBDateToString(convertUTCtoGB(rejected_at))
      })
    );
  }

  if (tags) {
    parsedTags = tags.join(', ');
  }
  if (statuses.length > 0) {
    parsedStatuses = statuses.join(', \n');
  }

  return {
    statuses: parsedStatuses,
    tags: parsedTags
  };
};

export const isObjectEmpty = (object) => {
  if (!object) return true;

  return Object.keys(object).length === 0;
};

export const convertMessageToHtmlAndTrimSpaces = (message) => {
  return message.toString('html').replace(/&nbsp;/g, '');
};

export const convertHtmlToString = (value) => {
  if (!value) return '';

  const cleanedString = value.toString('html').replace(/<[^>]+>/g, '');
  const tempElement = document.createElement('textarea');
  tempElement.innerHTML = cleanedString;

  return tempElement.value;
};
