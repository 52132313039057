import PropTypes from 'prop-types';
import { ReactComponent as CheckBoxIcon } from './checkbox.svg';
import { classNames } from '../utils';

export const CheckBox = ({
  isActive,
  isReverseView,
  currency,
  value,
  content,
  onChange,
  showCheckBox,
  className,
  disabled = false
}) => {
  const checkBoxClasses = classNames({
    'dropdown-choice': true,
    active: isActive,
    disabled: disabled,
    [className]: className
  });

  const handleCheckBox = () => {
    !disabled && onChange();
  };

  return (
    <div className={checkBoxClasses} role='button' tabIndex={0} onClick={handleCheckBox} onKeyDown={handleCheckBox}>
      {isReverseView ? (
        <>
          {showCheckBox && <CheckBoxIcon className='checkbox' />}
          {value && (
            <p className={currency ? 'dropdown-choice-value-with-currency' : 'dropdown-choice-value'}>
              {currency && <span className='dropdown-choice-currency'>{currency}</span>}
              {value}
            </p>
          )}
          {content}
        </>
      ) : (
        <>
          {value && (
            <p className={currency ? 'dropdown-choice-value-with-currency' : 'dropdown-choice-value'}>
              {currency && <span className='dropdown-choice-currency'>{currency}</span>}
              {value}
            </p>
          )}
          {content}
          {showCheckBox && <CheckBoxIcon className='checkbox' />}
        </>
      )}
    </div>
  );
};

CheckBox.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isReverseView: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  showCheckBox: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool
};
