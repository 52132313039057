import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './TransactionNotesHeadRow.scss';

export const TRANSACTION_NOTES_TABLE_HEADER = [
  {
    label: 'transactionId',
    width: '20%'
  },
  {
    label: 'authorAndDate',
    width: '20%'
  },
  {
    label: 'note',
    width: '60%'
  }
];

export const TransactionNotesHeadRow = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {TRANSACTION_NOTES_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'transactionNotes-table-head'} width={head.width}>
          <div className={'transactionNotes-table-head-block'}>
            <span>{i18n.getMessage(`transactionNotes.table.header.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};
