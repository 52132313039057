import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserPanel from 'components/Header/UserPanel';
import { getNavigationButtons } from 'components/MenuButtons/MenuButtons';
import { IconButton } from 'uikit/IconButton/IconButton';
import './NavigationBar.scss';

const NavigationBar = ({ compact, isMobile, collapseMenu }) => {
  const location = useLocation();

  const onClick = (buttonClick) => {
    buttonClick && buttonClick();
    collapseMenu();
  };

  return (
    <nav id={'navbar'} className={`navbar ${compact ? 'collapsed' : ''}`}>
      <div className={`navigation-wrapper ${compact && !isMobile ? 'collapsed' : ''}`}>
        {getNavigationButtons().map((button) => (
          <IconButton
            key={button.id}
            navigationType
            onClick={isMobile ? () => onClick(button.onClick) : null}
            path={button.path}
            text={button.text}
            Icon={button.icon}
            active={location.pathname === button.path}
            tooltipText={compact ? button.text : null}
            isDisabled={button.isDisabled}
          />
        ))}
        {isMobile && <UserPanel isMobile={isMobile} onClick={onClick} />}
      </div>
    </nav>
  );
};

NavigationBar.propTypes = {
  collapseMenu: PropTypes.func,
  isMobile: PropTypes.bool,
  compact: PropTypes.bool
};

export default NavigationBar;
