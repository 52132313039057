import { Fragment, useContext } from 'react';
import { Placeholder } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { CARD_AUTHORIZATION_STATUS } from 'components/constants';
import i18nContext from 'components/i18n-context';
import { dateToString, formatMoney } from 'services/utils';
import Button from 'uikit/Button/Button';
import './CardAuthorisationsTable.scss';

const CardAuthorisationsTable = ({ isLoading, cardAuthorizationData, releaseCardAuthorization }) => {
  const i18n = useContext(i18nContext);

  const getCardAuthorizationInfoBlock = (cardAuthorizationBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'card-authorizations-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (Array.isArray(cardAuthorizationBlockInfo)) {
      return (
        <p>
          {cardAuthorizationBlockInfo.map((item, index) => {
            if (item) {
              return (
                <Fragment key={index}>
                  {item}
                  {index < cardAuthorizationBlockInfo.length - 1 && <br />}
                </Fragment>
              );
            }
          })}
        </p>
      );
    } else {
      return <p>{cardAuthorizationBlockInfo}</p>;
    }
  };

  const isReleaseAvailable = () => {
    return (
      cardAuthorizationData.status === CARD_AUTHORIZATION_STATUS.ACCEPTED &&
      moment(cardAuthorizationData.updated_at).isBefore(moment().subtract(1, 'day'))
    );
  };

  return (
    <tr>
      <td className={'card-authorizations-table-cell'}>
        {getCardAuthorizationInfoBlock(cardAuthorizationData.authorization_id)}
      </td>
      <td className={'card-authorizations-table-cell'}>
        {getCardAuthorizationInfoBlock([cardAuthorizationData.card_id, `(${cardAuthorizationData.card_number})`])}
      </td>
      <td className={'card-authorizations-table-cell'}>
        {getCardAuthorizationInfoBlock(formatMoney(cardAuthorizationData.amount, cardAuthorizationData.currency, 2))}
      </td>
      <td className={'card-authorizations-table-cell'}>
        {getCardAuthorizationInfoBlock(cardAuthorizationData.remaining)}
      </td>
      <td className={'card-authorizations-table-cell'}>
        {getCardAuthorizationInfoBlock(cardAuthorizationData.currency)}
      </td>
      <td className={'card-authorizations-table-cell'}>
        {getCardAuthorizationInfoBlock(
          `${i18n.getMessage(`cardAuthorizationStatus.${cardAuthorizationData.status}`)} ${
            cardAuthorizationData.status_details ? '(' + cardAuthorizationData.status_details + ')' : ''
          }`
        )}
      </td>
      <td className={'card-authorizations-table-cell'}>
        {getCardAuthorizationInfoBlock(dateToString(cardAuthorizationData.updated_at))}
      </td>
      <td className={'card-authorizations-table-cell'}>
        {getCardAuthorizationInfoBlock(cardAuthorizationData.external_reference)}
      </td>
      <td className={'card-authorizations-table-cell'}>
        {isReleaseAvailable() && (
          <div className={'card-authorizations-table-cell-actions'}>
            <Button
              className={'card-authorizations-release-money-button'}
              onClick={() => releaseCardAuthorization(cardAuthorizationData.authorization_id)}
              type={'primary'}
              size={'small'}
              fullWidth
              isDisabled={isLoading}
            >
              {i18n.getMessage('cardAuthorizations.table.action.releaseMoney')}
            </Button>
          </div>
        )}
      </td>
    </tr>
  );
};

CardAuthorisationsTable.propTypes = {
  isLoading: PropTypes.bool,
  cardAuthorizationData: PropTypes.object,
  releaseCardAuthorization: PropTypes.func
};

export default CardAuthorisationsTable;
