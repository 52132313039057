import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import i18nContext from 'components/i18n-context';
import { MESSAGE_TOPIC_STATUS } from 'components/constants';
import OperatorsAsyncSelect from 'components/OperatorsAsyncSelect';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './MessagesTopicsFiltersBar.scss';

const MessagesTopicsFiltersBar = ({ filters, handleSearchText, handleSetFilters }) => {
  const i18n = useContext(i18nContext);

  const messagesTopicStatusesOptions = Object.keys(MESSAGE_TOPIC_STATUS).map((status) => {
    return {
      key: MESSAGE_TOPIC_STATUS[status],
      value: i18n.getMessage('messages.topics.status.' + MESSAGE_TOPIC_STATUS[status])
    };
  });

  const handleSetOwnerOperator = (value) => {
    handleSetFilters('operator', value);
  };

  const handleChangeUnreadOnlyCheckbox = () => {
    handleSetFilters('unread_only', !filters.unread_only);
  };

  return (
    <div className={'messages-topics-filters-wrapper'}>
      <div className={'messages-topics-date-range-filters-wrapper'}>
        <div>
          <span>{i18n.getMessage('messages.topics.filter.from.label')}</span>
          <CustomDatePicker onChange={handleSetFilters} name={'from'} value={filters.from} />
        </div>
        <div>
          <span>{i18n.getMessage('messages.topics.filter.to.label')}</span>
          <CustomDatePicker onChange={handleSetFilters} name={'to'} value={filters.to} />
        </div>
        <div className={'messages-topics-date-range-filter-block'}>
          <span>{i18n.getMessage('messages.topics.filter.ownerOperator.label')}</span>
          <OperatorsAsyncSelect
            className={'messages-topics-date-range-filter'}
            handleValueChanged={handleSetOwnerOperator}
            value={filters.operator}
          />
        </div>
      </div>

      <InputDropDown
        className={'messages-topics-filter-wrapper'}
        label={i18n.getMessage('messages.topics.filter.status.label')}
        name={'statuses'}
        value={filters.statuses}
        options={messagesTopicStatusesOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <Input
        className={'messages-topics-filter-wrapper'}
        label={i18n.getMessage('messages.topics.filter.search.label')}
        placeholder={i18n.getMessage('messages.topics.filter.search.placeholder')}
        name={'search_text'}
        value={filters.search_text}
        onChange={handleSearchText}
        Icon={SearchIcon}
      />
      <CheckBox
        className={'messages-topics-checkbox'}
        isActive={filters.unread_only !== false}
        showCheckBox={true}
        onChange={handleChangeUnreadOnlyCheckbox}
        value={i18n.getMessage('messages.topics.filter.unreadOnly.label')}
      />
    </div>
  );
};

MessagesTopicsFiltersBar.propTypes = {
  filters: PropTypes.object,
  handleSetFilters: PropTypes.func,
  handleSearchText: PropTypes.func
};

export default MessagesTopicsFiltersBar;
