import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { convertHtmlToString, dateToString, getCustomerLink } from 'services/utils';
import Button from 'uikit/Button/Button';
import './TopicMessagesTableRow.scss';

const TopicMessagesTableRow = ({ isLoading, messageData, topicData, downloadMessageDocument }) => {
  const handleDownloadMessageDocument = async (documentId) => {
    await downloadMessageDocument(documentId);
  };

  const getTopicInfoBlock = (topicBlockInfo, accountData) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'topic-messages-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (accountData) {
      return (
        <NavLink
          className={'topic-message-id-text'}
          to={getCustomerLink(accountData.customerNumber, accountData.customerStatus, accountData.customerType)}
        >
          {topicBlockInfo}
        </NavLink>
      );
    } else if (Array.isArray(topicBlockInfo)) {
      return (
        <div className={'topic-message-id-text'}>
          {topicBlockInfo.map((document, index) => {
            if (document) {
              return (
                <Fragment key={index}>
                  <Button
                    className={'link-button topic-message-id-text message-document-button'}
                    onClick={async () => await handleDownloadMessageDocument(document.id)}
                    isDisabled={false}
                  >
                    {document.name}
                  </Button>
                  {index < topicBlockInfo.length - 1 && <br />}
                </Fragment>
              );
            }
          })}
        </div>
      );
    } else {
      return <p>{topicBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'topic-messages-table-cell'}>
        {getTopicInfoBlock(topicData.accountName, {
          customerNumber: topicData.accountNumber,
          customerName: topicData.accountName,
          customerType: topicData.accountType,
          customerStatus: topicData.accountStatus
        })}
      </td>
      <td className={'topic-messages-table-cell message-table-cell'}>
        {getTopicInfoBlock(convertHtmlToString(messageData.messageBody))}
      </td>
      <td className={'topic-messages-table-cell message-documents-table-cell'}>
        {getTopicInfoBlock(messageData.documents)}
      </td>
      <td className={'topic-messages-table-cell'}>{getTopicInfoBlock(dateToString(messageData.createdAt))}</td>
    </tr>
  );
};

TopicMessagesTableRow.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  messageData: PropTypes.object.isRequired,
  topicData: PropTypes.object.isRequired,
  downloadMessageDocument: PropTypes.func.isRequired
};

export default TopicMessagesTableRow;
