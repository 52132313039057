import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import i18nContext from 'components/i18n-context';
import { CORPORATE_ACCOUNT_DETAILS_UPDATE, hasRole } from 'services/roles';
import { countriesOptions, passCheckOptions, yesOrNoOPTIONS } from '../../companyStructureFieldsOption';

const Director = ({ propertyPrefix, data, errors, handleChange, setFieldValue, handleDelete }) => {
  const i18n = useContext(i18nContext);
  const isDisabled = !hasRole(CORPORATE_ACCOUNT_DETAILS_UPDATE);

  const handleChangeCustomInput = (name, value) => {
    setFieldValue(name, value);
  };

  return (
    <>
      <tr className={'table-row'}>
        <td colSpan={2} className={'table-cell'}>
          <div className={'table-cell-title'}>
            {i18n.getMessage('companyStructure.director.title', { name: data.name, surname: data.surname })}

            <Button type={'outline'} onClick={handleDelete} isDisabled={isDisabled}>
              {i18n.getMessage('companyStructure.director.button.delete')}
            </Button>
          </div>
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.name')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.name'}
            value={data.name}
            onChange={handleChange}
            error={errors?.name}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.surname')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.surname'}
            value={data.surname}
            onChange={handleChange}
            error={errors?.surname}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.birthday')}</td>
        <td className={'table-cell'}>
          <CustomDatePicker
            isDisabled={isDisabled}
            name={propertyPrefix + '.birthday'}
            value={data.birthday}
            onChange={handleChangeCustomInput}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.passportNumber')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.passportNumber'}
            value={data.passportNumber}
            onChange={handleChange}
            error={errors?.passportNumber}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.passportIssueCountry')}</td>
        <td className={'table-cell'}>
          <InputDropDown
            isDisabled={isDisabled}
            className={'table-cell-input'}
            options={countriesOptions}
            name={propertyPrefix + '.passportIssueCountry'}
            value={data.passportIssueCountry}
            onChange={handleChangeCustomInput}
          />
        </td>
      </tr>

      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.passportIssueDate')}</td>
        <td className={'table-cell'}>
          <CustomDatePicker
            isDisabled={isDisabled}
            name={propertyPrefix + '.passportIssueDate'}
            value={data.passportIssueDate}
            onChange={handleChangeCustomInput}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.passportExpiryDate')}</td>
        <td className={'table-cell'}>
          <CustomDatePicker
            isDisabled={isDisabled}
            name={propertyPrefix + '.passportExpiryDate'}
            value={data.passportExpiryDate}
            onChange={handleChangeCustomInput}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.address1')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.address.address1'}
            value={data.address.address1}
            error={errors?.address?.address1}
            onChange={handleChange}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.address2')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.address.address2'}
            value={data.address.address2}
            error={errors?.address?.address2}
            onChange={handleChange}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.zipCode')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.address.zipCode'}
            value={data.address.zipCode}
            error={errors?.address?.zipCode}
            onChange={handleChange}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.city')}</td>
        <td className={'table-cell'}>
          <Input
            isDisabled={isDisabled}
            className={'table-cell-input'}
            name={propertyPrefix + '.address.city'}
            value={data.address.city}
            error={errors?.address?.city}
            onChange={handleChange}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.country')}</td>
        <td className={'table-cell'}>
          <InputDropDown
            isDisabled={isDisabled}
            className={'table-cell-input'}
            options={countriesOptions}
            name={propertyPrefix + '.address.country'}
            error={errors?.address?.country}
            value={data.address.country}
            onChange={handleChangeCustomInput}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.passportCheck')}</td>
        <td className={'table-cell'}>
          <InputDropDown
            isDisabled={isDisabled}
            className={'table-cell-input'}
            options={passCheckOptions}
            name={propertyPrefix + '.passportCheck'}
            value={data.passportCheck}
            onChange={handleChangeCustomInput}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.sanctionList')}</td>
        <td className={'table-cell'}>
          <InputDropDown
            isDisabled={isDisabled}
            className={'table-cell-input'}
            options={passCheckOptions}
            name={propertyPrefix + '.sanctionList'}
            value={data.sanctionList}
            onChange={handleChangeCustomInput}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.pepList')}</td>
        <td className={'table-cell'}>
          <InputDropDown
            isDisabled={isDisabled}
            className={'table-cell-input'}
            options={passCheckOptions}
            name={propertyPrefix + '.pepList'}
            value={data.pepList}
            onChange={handleChangeCustomInput}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.blackList')}</td>
        <td className={'table-cell'}>
          <InputDropDown
            isDisabled={isDisabled}
            className={'table-cell-input'}
            options={passCheckOptions}
            name={propertyPrefix + '.blackList'}
            value={data.blackList}
            onChange={handleChangeCustomInput}
          />
        </td>
      </tr>
      <tr className={'table-row'}>
        <td className={'table-cell'}>{i18n.getMessage('companyStructure.fields.director')}</td>
        <td className={'table-cell'}>
          <InputDropDown
            isDisabled={isDisabled}
            className={'table-cell-input'}
            options={yesOrNoOPTIONS}
            name={propertyPrefix + '.director'}
            value={data.director}
            onChange={handleChangeCustomInput}
          />
        </td>
      </tr>
    </>
  );
};

Director.propTypes = {
  propertyPrefix: PropTypes.string,
  data: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default Director;
