import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DeleteIconButton } from 'assets/iconButtons/delete.svg';
import { Placeholder } from 'react-bootstrap';
import { convertUTCtoGB, parseGBDateToString } from 'services/utils';
import parse from 'html-react-parser';
import './NotesTabTableRow.scss';

const NotesTabTableRow = ({ isLoading, noteData, handleRemoveNote }) => {
  const formatDateTime = (noteDate) => {
    const date = parseGBDateToString(convertUTCtoGB(noteDate));

    return <i className={'customer-note-table-cell-noteInfo-createdDate'}>{date}</i>;
  };

  const handleDeleteNote = () => {
    handleRemoveNote(noteData.id);
  };

  const getCustomerNoteInfoBlock = (customerNoteBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as='p' animation='glow'>
          <Placeholder className={'customer-note-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{customerNoteBlockInfo}</p>;
    }
  };

  return (
    <tr key={noteData.id}>
      <td className={'customer-note-table-cell'}>
        <div className={'customer-note-table-cell-noteInfo'}>
          {getCustomerNoteInfoBlock(noteData.operator_name)}
          {getCustomerNoteInfoBlock(formatDateTime(noteData.created_at))}
        </div>
      </td>
      <td className={'customer-note-table-cell'}>
        <div className={'customer-notes-table-cell-note'}>
          <div>{parse(noteData.note)}</div>
          {noteData.deleteAvailable && (
            <div className={'customer-notes-table-cell-note-deleteIcon'}>
              <DeleteIconButton onClick={handleDeleteNote} />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

NotesTabTableRow.propTypes = {
  isLoading: PropTypes.bool,
  noteData: PropTypes.shape({
    id: PropTypes.number,
    created_at: PropTypes.string,
    note: PropTypes.string,
    operator_name: PropTypes.string,
    deleteAvailable: PropTypes.bool
  }),
  handleRemoveNote: PropTypes.func
};

export default NotesTabTableRow;
