import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RichTextEditor from 'react-rte/lib/RichTextEditor';
import DeleteIcon from 'assets/delete.svg';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import FileSelector from 'components/FileSelector';
import { YES_OR_NO, SUPPORTED_IMAGE_FORMATS } from 'components/constants';
import { convertHtmlToString } from 'services/utils';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './AmlTab.scss';

const AmlTab = ({
  loadAmlData,
  amlData,
  saveAmlData,
  images,
  handleImageUploaded,
  handleImageDeleted,
  handleDocumentUploaded,
  handleDocumentDeleted,
  handleExpiringDocumentUploaded,
  handleLoadDocument
}) => {
  const i18n = useContext(i18nContext);
  const [uploadExpiringDocument, setUploadExpiringDocument] = useState(null);
  const [formConfig, setFormConfig] = useState({ initialValues: {}, validationSchema: Yup.object() });

  useEffect(() => {
    loadAmlData();
  }, []);

  useEffect(() => {
    if (amlData) {
      const validationSchema = Yup.object().shape(
        amlData.reduce((schema, group) => {
          group.items.forEach((field) => {
            const fieldName = field.name || `field_${group.id}_${field.id}`;
            schema[fieldName] = field.metadata.type === 'text' ? Yup.string() : Yup.mixed();
          });
          return schema;
        }, {})
      );

      const initialValues = amlData.reduce((values, group) => {
        group.items.forEach((field) => {
          const fieldName = field.name || `field_${group.id}_${field.id}`;
          if (field.metadata.type === 'richText') {
            values[fieldName] = field.value ? field.value : RichTextEditor.createEmptyValue();
          } else {
            values[fieldName] = field.value;
          }
        });
        return values;
      }, {});

      setFormConfig({ initialValues, validationSchema });
    }
  }, [amlData]);

  const form = useFormik({
    initialValues: formConfig.initialValues,
    validationSchema: formConfig.validationSchema,
    onSubmit: (values) => {
      // TODO: Submit logic
    }
  });

  const { values, handleChange, setFieldValue } = form;

  const yesOrNoOptions = Object.values(YES_OR_NO).map((value) => ({
    key: value,
    value: i18n.getMessage(`customers.yesOrNoSelect.${value}`)
  }));

  const renderItem = (groupId, itemId, item) => {
    const fieldName = item.name || `field_${groupId}_${itemId}`;
    if (item.metadata.type === 'text') {
      return (
        <Input
          className={'table-cell-input'}
          id={`${groupId}/${itemId}`}
          name={fieldName}
          value={values[fieldName]}
          onChange={handleChange}
        />
      );
    } else if (item.metadata.type === 'richText') {
      return (
        <Input
          className={'customer-notes-container-input'}
          type={'text-editor'}
          name={fieldName}
          isDisabled={false}
          value={values[fieldName] ? values[fieldName] : RichTextEditor.createEmptyValue()}
          onChange={(value) => {
            setFieldValue(fieldName, value);
          }}
          max={1000}
          rows={5}
          subText={i18n.getMessage('customerNotes.input.note.symbolsLeft', {
            symbolsLeft: 1000 - convertHtmlToString(values[fieldName]).length
          })}
        />
      );
    } else if (item.metadata.type === 'selector') {
      return (
        <InputDropDown
          className={'table-cell-input'}
          name={fieldName}
          options={yesOrNoOptions}
          value={values[fieldName]}
          onChange={(name, value) => setFieldValue(name, value)}
        />
      );
    } else if (item.metadata.type === 'images') {
      return (
        <div className='image-upload-container'>
          {item.value.map((image, index) => (
            <div key={image} className='image-wrapper'>
              <img
                id={`${groupId}/${itemId}/${index}`}
                src={images.find((img) => img.id === image)?.image}
                alt={image}
                className='uploaded-image'
              />
              {/*eslint-disable-next-line*/}
              <img
                src={DeleteIcon}
                alt='delete'
                onClick={() => handleImageDeleted(groupId, itemId, image)}
                className='delete-icon'
              />
            </div>
          ))}
          <div className='file-selector-wrapper'>
            <FileSelector
              id={`${groupId}/${itemId}`}
              onFileSelected={(file) => handleImageUploaded(groupId, itemId, file)}
              name={'Upload image'}
              accept={SUPPORTED_IMAGE_FORMATS}
            />
          </div>
        </div>
      );
    } else if (item.metadata.type === 'documents') {
      return (
        <div>
          {item.value.map((document) => (
            <div key={document.id} className='document-wrapper'>
              {/*eslint-disable-next-line*/}
              <a onClick={() => handleLoadDocument(document.id)} className='document-link'>
                {document.name}
              </a>
              {/*eslint-disable-next-line*/}
              <img
                src={DeleteIcon}
                alt='delete'
                onClick={() => handleDocumentDeleted(groupId, itemId, document.id)}
                className='delete-icon'
              />
            </div>
          ))}
          <div className='aml-add-document-wrapper'>
            {item.metadata.expiring ? (
              <Button
                className='aml-add-document-button'
                id={`${groupId}/${itemId}`}
                type='primary'
                size='medium'
                onClick={() => setUploadExpiringDocument({ groupId, itemId })}
              >
                {i18n.getMessage('amlTab.button.addDocument')}
              </Button>
            ) : (
              <FileSelector
                id={`${groupId}/${itemId}`}
                onFileSelected={(file) => handleDocumentUploaded(groupId, itemId, file)}
              />
            )}
          </div>
        </div>
      );
    }
  };

  const renderGroup = (group, index) => {
    if (group.items.length) {
      const selector = group.items.find((el) => el.metadata.type === 'selector');

      return (
        <table className='table table-bordered aml-table' key={index}>
          {group.name && (
            <thead className='thead-light'>
              <tr>
                <th style={{ width: '80%' }} colSpan={selector ? 1 : 2}>
                  {group.name}
                </th>
                {selector && (
                  <th style={{ width: '20%', alignContent: 'center' }}>
                    <InputDropDown
                      className='table-cell-input'
                      type='select'
                      name={selector.name || `selector_${index}`}
                      value={selector.value}
                      options={yesOrNoOptions}
                      onChange={(name, value) => setFieldValue(name, value)}
                    />
                  </th>
                )}
              </tr>
            </thead>
          )}
          <tbody>
            {group.items.map((item, itemIndex) => {
              if (item.metadata.type === 'selector') {
                return null;
              } else {
                return (
                  <tr key={`${index}/${itemIndex}`}>
                    {item.name && (
                      <td colSpan={item.metadata.type === 'description' ? 2 : 1} style={{ width: '30%' }}>
                        <div>{item.name}</div>
                      </td>
                    )}
                    {item.metadata.type !== 'description' && (
                      <td colSpan={item.name ? 1 : 2} style={{ width: '70%' }}>
                        {renderItem(index, itemIndex, item)}
                      </td>
                    )}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      );
    } else {
      return (
        <h3 key={index} id={index} className='font-weight-bold'>
          {group.name}
        </h3>
      );
    }
  };

  if (!amlData) {
    return <Loader className='application-loader aml-tab-loader' />;
  }

  return <div>{amlData.map((group, index) => renderGroup(group, index))}</div>;
};

AmlTab.propTypes = {
  loadAmlData: PropTypes.func,
  saveAmlData: PropTypes.func,
  amlData: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
  images: PropTypes.array,
  handleImageUploaded: PropTypes.func,
  handleImageDeleted: PropTypes.func,
  handleDocumentUploaded: PropTypes.func,
  handleDocumentDeleted: PropTypes.func,
  handleExpiringDocumentUploaded: PropTypes.func,
  handleLoadDocument: PropTypes.func
};

export default AmlTab;
