import { Fragment, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { convertHtmlToString, dateToString, getCustomerLink } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import './MessagesTopicsTableRow.scss';

const MessagesTopicsTableRow = ({ isLoading, topicData }) => {
  const i18n = useContext(i18nContext);

  const getTopicInfoBlock = (
    topicBlockInfo,
    topicId = undefined,
    lastMessageBody = undefined,
    isReadByOperator = undefined,
    accountData = undefined
  ) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'message-topics-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (topicId) {
      return (
        <div className={'message-topic-subject-wrapper'}>
          <NavLink
            className={`message-topic-id-text message-topic-name ${isReadByOperator ? '' : 'is-unread'}`}
            to={`${ROUTE_PATHS.MESSAGES}/${topicId}`}
          >
            {topicBlockInfo}
            <br />
          </NavLink>
          <p className={'message-topic-last-message'}>{lastMessageBody}</p>
        </div>
      );
    } else if (accountData) {
      return (
        <NavLink
          className={'message-topic-id-text'}
          to={getCustomerLink(accountData.customerNumber, accountData.customerStatus, accountData.customerType)}
        >
          {topicBlockInfo}
        </NavLink>
      );
    } else if (Array.isArray(topicBlockInfo)) {
      return (
        <p>
          {topicBlockInfo.map((item, index) => {
            if (item) {
              return (
                <Fragment key={index}>
                  {item}
                  {index < topicBlockInfo.length - 1 && <br />}
                </Fragment>
              );
            }
          })}
        </p>
      );
    } else {
      return <p>{topicBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'message-topics-table-cell'}>
        {getTopicInfoBlock(
          topicData.subject,
          topicData.id,
          convertHtmlToString(topicData.lastMessageBody),
          topicData.readByOperator
        )}
      </td>
      <td className={'message-topics-table-cell'}>{getTopicInfoBlock(topicData?.ownerOperator?.name)}</td>
      <td className={'message-topics-table-cell'}>
        {getTopicInfoBlock(topicData.accountName, undefined, undefined, undefined, {
          customerNumber: topicData.accountNumber,
          customerName: topicData.accountName,
          customerType: topicData.accountType,
          customerStatus: topicData.accountStatus
        })}
      </td>
      <td className={'message-topics-table-cell'}>
        {getTopicInfoBlock(i18n.getMessage(`customers.status.${topicData.accountStatus}`))}
      </td>
      <td className={'message-topics-table-cell'}>
        {getTopicInfoBlock(i18n.getMessage(`messages.topics.status.${topicData.status}`))}
      </td>
      <td className={'message-topics-table-cell'}>{getTopicInfoBlock(dateToString(topicData.lastMessageCreatedAt))}</td>
    </tr>
  );
};

MessagesTopicsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  topicData: PropTypes.object
};

export default MessagesTopicsTableRow;
