import { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import './NotesTabHeadRow.scss';

export const NOTES_TAB_TABLE_HEADER = [
  {
    label: 'authorAndDate',
    width: '30%'
  },
  {
    label: 'note',
    width: '70%'
  }
];

export const NotesTabHeadRow = () => {
  const i18n = useContext(i18nContext);

  return (
    <tr>
      {NOTES_TAB_TABLE_HEADER.map((head) => (
        <th key={head.label} className={'customerNotes-table-head'} width={head.width}>
          <div className={'customerNotes-table-head-block'}>
            <span>{i18n.getMessage(`customerNotes.table.header.${head.label}`)}</span>
          </div>
        </th>
      ))}
    </tr>
  );
};
