import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import { CUSTOMER_TYPE, DIRECTION, KYC_STATUSES } from 'components/constants';
import {
  addNewPaymentProviderRequest,
  addNoteRequest,
  addRepresentativeRequest,
  applyTariffRequest,
  blockCustomerCardRequest,
  changeCustomerTariffGroupRequest,
  deleteCompanyStructureMemberRequest,
  deleteCustomerDocumentRequest,
  deleteNoteRequest,
  downloadDocumentByDocumentIdRequest,
  downloadPaymentTransactionDocumentRequest,
  exportBalancesList,
  exportCustomersList,
  getAmlRequest,
  getAmlTemplateRequest,
  getCompanyStructureRequest,
  getCorporateCustomerDetailsRequest,
  getCustomerCardsRequest,
  getCustomerDocumentsRequest,
  getCustomerLogsRequest,
  getCustomerNotesRequest,
  getCustomersListRequest,
  getCustomerTransactionsNotesRequest,
  getIndividualCustomerDetailsRequest,
  getLimitListRequest,
  getLinkedRuleEngineProviderRequest,
  getPaymentDocumentsRequest,
  getPaymentProvidersInfo,
  getTariffGroupsListRequest,
  getTariffsGroupRequest,
  linkRuleEngineProviderRequest,
  loadCustomerCsvReportRequest,
  loadCustomerPdfReportRequest,
  removeRepresentativeRequest,
  replaceCustomerCardRequest,
  requestExternalKyc,
  saveAmlRequest,
  sendCompanyStructureRequest,
  unblockCustomerCardRequest,
  unlinkRuleEngineProviderRequest,
  updateCardsPerAccountLimitRequest,
  updateCardsPerCustomerLimitRequest,
  updateCorporateCustomerDetailsRequest,
  updateCustomerDocumentRequest,
  updateCustomerEmailRequest,
  updateCustomerExternalKycReferenceRequest,
  updateCustomerStatusRequest,
  updateDirectorApprovalRequest,
  updateIndividualCustomerDetailsRequest,
  updateKycStatusRequest,
  updateMlroApprovalRequest,
  updateNextAMLCheckDateRequest,
  updateRepresentativeRequest,
  updateRiskProfileRequest,
  uploadCustomerDocumentRequest
} from 'services/requestAgent';

class CustomersStore {
  isInitialized = false;
  isLoading = false;
  isLoadingCustomerCsv = false;
  isLoadingBalancesCsv = false;
  isLoadingNotes = false;
  isLoadingLogs = false;
  isTariffsLoaded = false;
  isCompanyStructureLoaded = false;
  isLimitsLoaded = false;
  isShowActiveCustomers = false;
  isCurrentCustomerDetailsReceived = false;
  isCardActionsLoading = false;
  error = null;
  customers = [];
  currentCustomerDetails = {};
  currentCustomerProviders = null;
  currentCustomerLinkedRuleEngineProvider = null;
  currentCustomerCards = null;
  currentCustomerNotes = null;
  currentCustomerTransactionsNotes = null;
  currentCustomerLogs = null;
  currentCustomerMonitoringDocuments = null;
  currentCustomerAml = null;
  currentCustomerDocuments = null;
  firstAccountCreatedAt = null;
  companyStructure = {
    authorizedPerson: null,
    beneficiaries: [],
    directors: []
  };
  individualDocumentsInfo = null;
  limits = [];
  tariffs = [];
  selectedTariffsGroup = null;
  totalElements = null;
  sortColumn = {
    sort_column: 'created_at',
    sort_direction: DIRECTION.DESC
  };
  pagination = {
    size: 20,
    page: 0,
    totalPages: null
  };
  filters = {
    search_text: null,
    limit_groups: [],
    tariff_groups: [],
    types: [],
    account_statuses: [],
    risk_profiles: [],
    kyc_statuses: this.isShowActiveCustomers
      ? [KYC_STATUSES.VERIFIED]
      : Object.keys(KYC_STATUSES).filter((s) => s !== KYC_STATUSES.VERIFIED)
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetCustomersStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.isLoadingCustomerCsv = false;
    this.isLoadingBalancesCsv = false;
    this.isLoadingNotes = false;
    this.isLoadingLogs = false;
    this.isCompanyStructureLoaded = false;
    this.isTariffsLoaded = false;
    this.isLimitsLoaded = false;
    this.isCardActionsLoading = false;
    this.error = null;
    this.customers = [];
    this.currentCustomerDetails = {};
    this.currentCustomerProviders = null;
    this.currentCustomerLinkedRuleEngineProvider = null;
    this.currentCustomerCards = null;
    this.currentCustomerNotes = null;
    this.currentCustomerTransactionsNotes = null;
    this.currentCustomerLogs = null;
    this.currentCustomerMonitoringDocuments = null;
    this.currentCustomerAml = null;
    this.currentCustomerDocuments = null;
    this.firstAccountCreatedAt = null;
    this.companyStructure = {
      authorizedPerson: null,
      beneficiaries: [],
      directors: []
    };
    this.limits = [];
    this.tariffs = [];
    this.selectedTariffsGroup = null;
    this.totalElements = null;
    this.sortColumn = {
      sort_column: 'created_at',
      sort_direction: DIRECTION.DESC
    };
    this.pagination = {
      size: 20,
      page: 0,
      totalPages: null
    };
    this.filters = {
      search_text: null,
      limit_groups: [],
      tariff_groups: [],
      types: [],
      account_statuses: [],
      risk_profiles: [],
      kyc_statuses: this.isShowActiveCustomers
        ? [KYC_STATUSES.VERIFIED]
        : Object.keys(KYC_STATUSES).filter((s) => s !== KYC_STATUSES.VERIFIED)
    };
  };

  resetCustomersStorePagination = () => {
    this.totalElements = null;
    this.pagination = {
      size: 20,
      page: 0,
      totalPages: null
    };
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setIsLoadingCustomersCsv = (status) => {
    this.isLoadingCustomerCsv = status;
    this.error = null;
  };

  setIsLoadingBalancesCsv = (status) => {
    this.isLoadingBalancesCsv = status;
    this.error = null;
  };

  setIsLoadingNotes = (status) => {
    this.isLoadingNotes = status;
    this.error = null;
  };

  setIsLoadingLogs = (status) => {
    this.isLoadingNotes = status;
    this.error = null;
  };

  setIsShowActiveCustomers = (status) => {
    this.isShowActiveCustomers = status;
  };

  setIsCardActionsLoading = (status) => {
    this.isCardActionsLoading = status;
  };

  setPageNumber = (page) => {
    this.pagination.page = page;
  };

  setPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setSortData = (sortData) => {
    this.sortColumn.sort_column = sortData.sortBy;
    this.sortColumn.sort_direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    const filters = { ...this.filters };
    const pagination = { ...this.pagination };

    // Mapping URL filter parameters to corresponding properties in the 'filters' object
    const filterParamsMapping = {
      search_text: 'search_text',
      limit_groups: 'limit_groups',
      tariff_groups: 'tariff_groups',
      types: 'types',
      account_statuses: 'account_statuses',
      risk_profiles: 'risk_profiles',
      kyc_statuses: 'kyc_statuses'
    };

    // Iterating over each URL parameter and assigning its values to 'filters'
    for (const param in params) {
      if (param in filterParamsMapping) {
        filters[filterParamsMapping[param]] = Array.isArray(params[param]) ? params[param] : [params[param]];
      }
    }

    // Assigning pagination parameters from the URL
    pagination.page = parseInt(params.page) || pagination.page;
    pagination.size = parseInt(params.size) || pagination.size;

    // Assigning sorting parameters from the URL
    this.sortColumn.sort_column = params.sort_column || this.sortColumn.sort_column;
    this.sortColumn.sort_direction = params.sort_direction || this.sortColumn.sort_direction;

    // Updating the state of filters and pagination
    this.filters = filters;
    this.pagination = pagination;
  };

  requestInitialData = async () => {
    this.setIsLoading(true);
    try {
      const tariffs = await getTariffGroupsListRequest();
      const limits = await getLimitListRequest();

      const res = await getCustomersListRequest(
        this.sortColumn.sort_column,
        this.pagination.page,
        this.pagination.size,
        this.sortColumn.sort_direction,
        this.filters.search_text,
        this.filters
      );

      runInAction(() => {
        this.isInitialized = true;
        this.isTariffsLoaded = true;
        this.isLimitsLoaded = true;
        this.tariffs = tariffs;
        this.limits = limits;
        this.customers = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getCustomersList = async () => {
    this.setIsLoading(true);
    try {
      const res = await getCustomersListRequest(
        this.sortColumn.sort_column,
        this.pagination.page,
        this.pagination.size,
        this.sortColumn.sort_direction,
        this.filters.search_text,
        this.filters
      );

      runInAction(() => {
        this.customers = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  prepareFiltersParams = () => {
    const params = { ...this.filters };
    params.balance_on_date = moment(params.balance_on_date || new Date()).format('YYYY-MM-DD');
    params.balance_on_time = (params.balance_on_time || moment(0).utc()).format('HH-mm-ss');

    return params;
  };

  handleExportCustomers = async () => {
    this.setIsLoadingCustomersCsv(true);
    try {
      await exportCustomersList(this.prepareFiltersParams());
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoadingCustomerCsv = false;
      });
    }
  };

  handleExportBalances = async () => {
    this.setIsLoadingBalancesCsv(true);
    try {
      await exportBalancesList(this.prepareFiltersParams());
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingBalancesCsv(false);
    }
  };

  getCustomerDetailsById = async (customerNumber, customerType) => {
    this.setIsLoading(true);
    try {
      let customerDetails = {};

      if (customerType === CUSTOMER_TYPE.INDIVIDUAL) {
        customerDetails = await getIndividualCustomerDetailsRequest(customerNumber);
      } else {
        customerDetails = await getCorporateCustomerDetailsRequest(customerNumber);
      }

      runInAction(() => {
        this.currentCustomerDetails = customerDetails;
        this.isCurrentCustomerDetailsReceived = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getCurrentCustomerProviders = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      const providers = await getPaymentProvidersInfo(customerNumber);

      runInAction(() => {
        this.currentCustomerProviders = providers;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  addNewPaymentProvider = async (customerNumber, paymentProvider) => {
    this.setIsLoading(true);
    try {
      await addNewPaymentProviderRequest(customerNumber, {
        transferProvider: paymentProvider,
        userInfo: null
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getLinkedRuleEngineProvider = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      const linkedRuleEngineProvider = await getLinkedRuleEngineProviderRequest(customerNumber);

      runInAction(() => {
        this.currentCustomerLinkedRuleEngineProvider = linkedRuleEngineProvider;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  linkRuleEngineProvider = async (customerNumber, ruleEngine) => {
    this.setIsLoading(true);
    try {
      const linkedRuleEngineProvider = await linkRuleEngineProviderRequest(customerNumber, ruleEngine);

      runInAction(() => {
        this.currentCustomerLinkedRuleEngineProvider = linkedRuleEngineProvider;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  unlinkRuleEngineProvider = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      await unlinkRuleEngineProviderRequest(customerNumber);

      runInAction(() => {
        this.currentCustomerLinkedRuleEngineProvider = null;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getCurrentCustomerCards = async (customerNumber) => {
    this.setIsCardActionsLoading(true);
    try {
      const cards = await getCustomerCardsRequest(customerNumber);

      runInAction(() => {
        this.currentCustomerCards = cards;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsCardActionsLoading(false);
    }
  };

  blockCustomerCard = async (customerNumber, cardId, cardBlockType) => {
    this.setIsCardActionsLoading(true);
    try {
      const cardInfo = await blockCustomerCardRequest(customerNumber, cardId, cardBlockType);

      runInAction(() => {
        const index = this.currentCustomerCards.findIndex((card) => card.card_id === cardId);

        if (index !== -1) {
          this.currentCustomerCards[index] = cardInfo;
        }
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsCardActionsLoading(false);
    }
  };

  unblockCustomerCard = async (customerNumber, cardId) => {
    this.setIsCardActionsLoading(true);
    try {
      const cardInfo = await unblockCustomerCardRequest(customerNumber, cardId);

      runInAction(() => {
        const index = this.currentCustomerCards.findIndex((card) => card.card_id === cardId);

        if (index !== -1) {
          this.currentCustomerCards[index] = cardInfo;
        }
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsCardActionsLoading(false);
    }
  };

  replaceCustomerCard = async (customerNumber, cardId) => {
    this.setIsCardActionsLoading(true);
    try {
      const cardInfo = await replaceCustomerCardRequest(customerNumber, cardId);

      runInAction(() => {
        const index = this.currentCustomerCards.findIndex((card) => card.card_id === cardId);

        if (index !== -1) {
          this.currentCustomerCards[index] = cardInfo;
        }
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsCardActionsLoading(false);
    }
  };

  updateCardsPerCustomerLimit = async (customerNumber, cardsLimit) => {
    this.setIsCardActionsLoading(true);
    try {
      const customerDetails = await updateCardsPerCustomerLimitRequest(customerNumber, cardsLimit);

      runInAction(() => {
        this.currentCustomerDetails = customerDetails;
        this.isCurrentCustomerDetailsReceived = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsCardActionsLoading(false);
    }
  };

  updateCardsPerAccountLimit = async (customerNumber, cardsLimit) => {
    this.setIsCardActionsLoading(true);
    try {
      const customerDetails = await updateCardsPerAccountLimitRequest(customerNumber, cardsLimit);

      runInAction(() => {
        this.currentCustomerDetails = customerDetails;
        this.isCurrentCustomerDetailsReceived = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsCardActionsLoading(false);
    }
  };

  handleUpdateCustomerDetails = async (customerNumber, data, customerType) => {
    this.setIsLoading(true);
    try {
      let customerDetails = {};

      if (customerType === CUSTOMER_TYPE.INDIVIDUAL) {
        customerDetails = await updateIndividualCustomerDetailsRequest(customerNumber, data);
      } else {
        customerDetails = await updateCorporateCustomerDetailsRequest(customerNumber, data);
      }

      runInAction(() => {
        this.currentCustomerDetails = { ...this.currentCustomerDetails, customerDetails };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeCustomerEmail = async (customerNumber, email) => {
    this.setIsLoading(true);
    try {
      const customerDetails = await updateCustomerEmailRequest(customerNumber, email);

      runInAction(() => {
        this.currentCustomerDetails = {
          ...this.currentCustomerDetails,
          email: customerDetails.email
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeCustomerStatus = async (customerNumber, customerStatus) => {
    this.setIsLoading(true);
    try {
      const customerDetails = await updateCustomerStatusRequest(customerNumber, customerStatus);

      runInAction(() => {
        this.currentCustomerDetails = {
          ...this.currentCustomerDetails,
          status: customerDetails.status
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeKycStatus = async (customerNumber, kycStatus) => {
    this.setIsLoading(true);
    try {
      const customerDetails = await updateKycStatusRequest(customerNumber, kycStatus);

      runInAction(() => {
        this.currentCustomerDetails = {
          ...this.currentCustomerDetails,
          kyc_status: customerDetails.kyc_status
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeExternalKycReference = async (customerNumber, externalKycReference) => {
    this.setIsLoading(true);
    try {
      const customerDetails = await updateCustomerExternalKycReferenceRequest(customerNumber, externalKycReference);

      runInAction(() => {
        this.currentCustomerDetails = {
          ...this.currentCustomerDetails,
          kycExternalReference: customerDetails.kycExternalReference
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeRiskProfile = async (customerNumber, status) => {
    this.setIsLoading(true);
    try {
      const customerDetails = await updateRiskProfileRequest(customerNumber, status);

      runInAction(() => {
        this.currentCustomerDetails = {
          ...this.currentCustomerDetails,
          risk_profile: customerDetails.risk_profile
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeMlroApproval = async (customerNumber, status) => {
    this.setIsLoading(true);
    try {
      const customerDetails = await updateMlroApprovalRequest(customerNumber, status);

      runInAction(() => {
        this.currentCustomerDetails = {
          ...this.currentCustomerDetails,
          mlro_approval: customerDetails.mlro_approval
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeDirectorApproval = async (customerNumber, status) => {
    this.setIsLoading(true);
    try {
      const customerDetails = await updateDirectorApprovalRequest(customerNumber, status);

      runInAction(() => {
        this.currentCustomerDetails = {
          ...this.currentCustomerDetails,
          director_approval: customerDetails.director_approval
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleChangeNextAMLCheckDate = async (customerNumber, status) => {
    this.setIsLoading(true);
    try {
      const customerDetails = await updateNextAMLCheckDateRequest(customerNumber, status);

      runInAction(() => {
        this.currentCustomerDetails = {
          ...this.currentCustomerDetails,
          next_aml_check_date: customerDetails.next_aml_check_date
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleReceiveKycData = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      await requestExternalKyc(customerNumber);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getCompanyStructure = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      const { authorized_person, beneficiaries, directors } = await getCompanyStructureRequest(customerNumber);

      runInAction(() => {
        this.companyStructure.authorizedPerson = authorized_person;
        this.companyStructure.beneficiaries = beneficiaries;
        this.companyStructure.directors = directors;
        this.isCompanyStructureLoaded = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  sendCompanyStructure = async (customerNumber, companyStructure) => {
    this.setIsLoading(true);
    try {
      const { authorized_person, beneficiaries, directors } = await sendCompanyStructureRequest(
        customerNumber,
        companyStructure
      );

      runInAction(() => {
        this.companyStructure.authorizedPerson = authorized_person;
        this.companyStructure.beneficiaries = beneficiaries;
        this.companyStructure.directors = directors;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  deleteCompanyStructureMember = async (customerNumber, memberCustomerNumber) => {
    this.setIsLoading(true);
    try {
      const { authorized_person, beneficiaries, directors } = await deleteCompanyStructureMemberRequest(
        customerNumber,
        memberCustomerNumber
      );

      runInAction(() => {
        this.companyStructure.authorizedPerson = authorized_person;
        this.companyStructure.beneficiaries = beneficiaries;
        this.companyStructure.directors = directors;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getTariffGroupsList = async () => {
    this.setIsLoading(true);
    try {
      const tariffsList = await getTariffGroupsListRequest();

      runInAction(() => {
        this.tariffs = tariffsList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getTariffsGroup = async (groupId) => {
    this.setIsLoading(true);
    try {
      const tariffsGroup = await getTariffsGroupRequest(groupId);

      runInAction(() => {
        this.selectedTariffsGroup = tariffsGroup;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  changeCustomerTariffGroup = async (customerNumber, groupId) => {
    this.setIsLoading(true);
    try {
      const tariffsGroup = await changeCustomerTariffGroupRequest(customerNumber, groupId);

      runInAction(() => {
        this.selectedTariffsGroup = tariffsGroup;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  applyTariff = async (customerNumber, tariffType) => {
    this.setIsLoading(true);
    try {
      await applyTariffRequest(customerNumber, tariffType);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  addRepresentative = async (customerNumber, email, permissions) => {
    this.setIsLoading(true);
    try {
      const newRepresentative = await addRepresentativeRequest(customerNumber, email, permissions);

      runInAction(() => {
        this.currentCustomerDetails.representatives = [
          ...this.currentCustomerDetails.representatives,
          newRepresentative
        ];
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  removeRepresentative = async (customerNumber, representativeId) => {
    this.setIsLoading(true);
    try {
      await removeRepresentativeRequest(customerNumber, representativeId);

      runInAction(() => {
        this.currentCustomerDetails.representatives = this.currentCustomerDetails.representatives.filter(
          (representative) => representative.id !== representativeId
        );
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  updateRepresentativePermissions = async (customerNumber, representativeId, permissions) => {
    this.setIsLoading(true);
    try {
      const representativeDetails = await updateRepresentativeRequest(customerNumber, representativeId, permissions);

      runInAction(() => {
        this.currentCustomerDetails.representatives = this.currentCustomerDetails.representatives.map((rep) =>
          rep.id === representativeDetails.id ? representativeDetails : rep
        );
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  setDeleteAvailability = (note) => {
    const deleteAvailable = note.delete_expires_in > 0;
    note.deleteAvailable = deleteAvailable;

    if (deleteAvailable) {
      setTimeout(() => {
        runInAction(() => {
          this.currentCustomerNotes = this.currentCustomerNotes.map((el) =>
            el.id === note.id ? { ...el, deleteAvailable: false } : el
          );
        });
      }, note.delete_expires_in * 1000);
    }

    return note;
  };

  loadCustomerNotes = async (customerNumber) => {
    this.setIsLoadingNotes(true);
    try {
      const customerNotes = await getCustomerNotesRequest(customerNumber);

      const checkedNotes = customerNotes.map(this.setDeleteAvailability);

      runInAction(() => {
        this.currentCustomerNotes = checkedNotes;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingNotes(false);
    }
  };

  loadCustomerTransactionNotes = async (customerNumber) => {
    this.setIsLoadingNotes(true);
    try {
      const res = await getCustomerTransactionsNotesRequest(customerNumber, {
        page: this.pagination.page,
        size: this.pagination.size,
        totalPages: this.pagination.totalPages,
        totalElements: this.totalElements
      });

      runInAction(() => {
        this.currentCustomerTransactionsNotes = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingNotes(false);
    }
  };

  addNote = async (customerNumber, transactionNumber, note) => {
    this.setIsLoadingNotes(true);
    try {
      const newNote = await addNoteRequest(customerNumber, transactionNumber, note);

      const checkedNote = this.setDeleteAvailability(newNote);

      runInAction(() => {
        this.currentCustomerNotes = [checkedNote, ...this.currentCustomerNotes];
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingNotes(false);
    }
  };

  deleteNote = async (noteId) => {
    this.setIsLoadingNotes(true);
    try {
      await deleteNoteRequest(noteId);

      runInAction(() => {
        this.currentCustomerNotes = this.currentCustomerNotes.filter((note) => note.id !== noteId);
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingNotes(false);
    }
  };

  loadCustomerLogs = async (customerNumber) => {
    this.setIsLoadingLogs(true);
    try {
      const res = await getCustomerLogsRequest(customerNumber, {
        page: this.pagination.page,
        size: this.pagination.size,
        totalPages: this.pagination.totalPages,
        totalElements: this.totalElements
      });

      runInAction(() => {
        this.currentCustomerLogs = res.logs.content;
        this.firstAccountCreatedAt = res.first_wallet_creation_date;
        this.totalElements = res.logs.total_elements;
        this.pagination = {
          size: res.logs.size,
          page: res.logs.number,
          totalPages: res.logs.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingLogs(false);
    }
  };

  loadCustomerMonitoringDocuments = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      const documents = await getPaymentDocumentsRequest(customerNumber);

      runInAction(() => {
        this.currentCustomerMonitoringDocuments = documents;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  downloadCustomerMonitoringDocumentById = async (documentId) => {
    this.setIsLoading(true);
    try {
      await downloadPaymentTransactionDocumentRequest(documentId);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getAmlTemplate = async (customerType) => {
    this.setIsLoading(true);
    try {
      const amlTemplate = await getAmlTemplateRequest(customerType);

      runInAction(() => {
        this.currentCustomerAml = amlTemplate;
        this.error = null;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getCustomerAml = async (customerNumber, customerType) => {
    this.setIsLoading(true);
    try {
      const customerAml = await getAmlRequest(customerNumber);

      runInAction(() => {
        this.currentCustomerAml = customerAml;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });

      if (err.code === 'BAD_REQUEST' && err.message === 'Aml not found.') {
        await this.getAmlTemplate(customerType);
      }
    } finally {
      this.setIsLoading(false);
    }
  };

  saveAmlForm = async (customerNumber, aml) => {
    this.setIsLoading(true);
    try {
      const customerAml = await saveAmlRequest(customerNumber, aml);

      runInAction(() => {
        this.currentCustomerAml = customerAml;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  loadCustomerPdfReport = async (customerNumber, from, to) => {
    this.setIsLoading(true);
    try {
      await loadCustomerPdfReportRequest(customerNumber, from, to);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  loadCustomerCsvReport = async (customerNumber, from, to) => {
    this.setIsLoading(true);
    try {
      await loadCustomerCsvReportRequest(customerNumber, from, to);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  getCustomerDocuments = async (customerNumber) => {
    this.setIsLoading(true);
    try {
      const customerDocuments = await getCustomerDocumentsRequest(customerNumber);

      runInAction(() => {
        this.currentCustomerDocuments = customerDocuments;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  uploadCustomerDocument = async (customerNumber, data) => {
    this.setIsLoading(true);
    try {
      const newDocument = await uploadCustomerDocumentRequest(customerNumber, data);

      runInAction(() => {
        this.currentCustomerDocuments = [...this.currentCustomerDocuments, newDocument];
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  updateCustomerDocument = async (customerNumber, documentId, data) => {
    this.setIsLoading(true);
    try {
      const updatedDocument = await updateCustomerDocumentRequest(customerNumber, documentId, data);

      runInAction(() => {
        this.currentCustomerDocuments = this.currentCustomerDocuments.map((document) =>
          document.documentUid === updatedDocument.documentUid ? updatedDocument : document
        );
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  deleteCustomerDocument = async (customerNumber, documentId) => {
    this.setIsLoading(true);
    try {
      await deleteCustomerDocumentRequest(customerNumber, documentId);

      runInAction(() => {
        this.currentCustomerDocuments = this.currentCustomerDocuments.filter(
          (document) => document.documentUid !== documentId
        );
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  downloadCustomerDocumentById = async (customerNumber, documentId) => {
    this.setIsLoading(true);
    try {
      await downloadDocumentByDocumentIdRequest(customerNumber, documentId);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };
}

export default new CustomersStore();
