import { Fragment, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { SHOW_LIMITS_IN_CRM } from 'config/config';
import { formatMoney, getCustomerLink, parseDateUs } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import './CustomersTableRow.scss';
import { CUSTOMER_TYPE } from '../../../../../../components/constants';

export const CustomersTableRow = ({ isLoading, customerData }) => {
  const i18n = useContext(i18nContext);

  const getUserInfoBlock = (userBlockInfo, isCustomerId = false) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'customers-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isCustomerId) {
      return (
        <NavLink
          className={'customer-id-text'}
          to={getCustomerLink(userBlockInfo, customerData.status, customerData.type)}
        >
          {userBlockInfo}
        </NavLink>
      );
    } else if (Array.isArray(userBlockInfo)) {
      return (
        <p>
          {userBlockInfo.map((item, index) => {
            if (item) {
              return (
                <Fragment key={index}>
                  {item}
                  {index < userBlockInfo.length - 1 && <br />}
                </Fragment>
              );
            }
          })}
        </p>
      );
    } else {
      return <p>{userBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'customers-table-cell'}>{getUserInfoBlock(customerData.account_number, true)}</td>
      <td className={'customers-table-cell'}>{getUserInfoBlock(customerData.user.id)}</td>
      <td className={'customers-table-cell'}>{getUserInfoBlock(customerData.common_name)}</td>
      <td className={'customers-table-cell'}>{getUserInfoBlock(customerData.type)}</td>
      <td className={'customers-table-cell'}>
        {getUserInfoBlock([
          i18n.getMessage(`kyc.status.${customerData.kyc_status}`),
          i18n.getMessage(`users.status.${customerData.user.status}`),
          i18n.getMessage(`customers.status.${customerData.status}`)
        ])}
      </td>
      <td className={'customers-table-cell'}>{getUserInfoBlock(customerData.user.phone)}</td>
      <td className={'customers-table-cell'}>
        {getUserInfoBlock(
          SHOW_LIMITS_IN_CRM
            ? customerData.limit_group
            : i18n.getMessage(`country.${customerData.legal_address?.country}`)
        )}
      </td>
      <td className={'customers-table-cell'}>{getUserInfoBlock(customerData.tariff_group)}</td>
      <td className={'customers-table-cell'}>
        {getUserInfoBlock([
          parseDateUs(customerData.user.last_login_date),
          parseDateUs(customerData.user.registration_date)
        ])}
      </td>
      <td className={'customers-table-cell'}>
        {isLoading ? (
          <Placeholder as={'p'} animation={'glow'}>
            <Placeholder className={'customers-loader-placeholder'} xs={12} bg={'secondary'} />
          </Placeholder>
        ) : (
          customerData.wallets.map(({ wallet_number, available, currency }) => (
            <div key={wallet_number + currency}>{currency + ' ' + formatMoney(available, currency)}</div>
          ))
        )}
      </td>
      <td className={'customers-table-cell'}>
        {isLoading ? (
          <Placeholder as={'p'} animation={'glow'}>
            <Placeholder className={'customers-loader-placeholder'} xs={12} bg={'secondary'} />
          </Placeholder>
        ) : (
          customerData.providers?.map(
            (provider) =>
              provider.external_reference && (
                <div key={customerData.account_number + provider.external_reference}>{provider.external_reference}</div>
              )
          )
        )}
      </td>
    </tr>
  );
};

CustomersTableRow.propTypes = {
  isLoading: PropTypes.bool,
  customerData: PropTypes.object
};
