import React from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from 'react-bootstrap';
import { convertUTCtoGB, parseGBDateToString } from 'services/utils';
import parse from 'html-react-parser';
import './TransactionNotesTableRow.scss';
import { NavLink } from 'react-router-dom';

const TransactionNotesTableRow = ({ isLoading, transactionNoteData, transactionId }) => {
  const formatDate = (transactionNoteDate) => {
    const date = parseGBDateToString(convertUTCtoGB(transactionNoteDate));

    return <i className={'transactionNote-table-cell-noteInfo-createdDate'}>{date}</i>;
  };

  const getTransactionNoteInfoBlock = (transactionNoteBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as='p' animation='glow'>
          <Placeholder className={'transactionNote-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{transactionNoteBlockInfo}</p>;
    }
  };

  return (
    <>
      {transactionNoteData.map((transactionNote, index) => {
        return (
          <tr key={index}>
            <td className={'transactionNote-table-cell'}>
              {index === 0 && (
                <NavLink className={'transactionNote-table-cell-id-text'} to={`/transactions/${transactionId}`}>
                  {transactionId}
                </NavLink>
              )}
            </td>
            <td className={'transactionNote-table-cell'}>
              <div className={'transactionNote-table-cell-noteInfo'}>
                {getTransactionNoteInfoBlock(transactionNote.operator_name)}
                {getTransactionNoteInfoBlock(formatDate(transactionNote.created_at))}
              </div>
            </td>
            <td className={'transactionNote-table-cell'}>
              <div className={'transactionNotes-table-cell-note'}>{parse(transactionNote.note)}</div>
            </td>
          </tr>
        );
      })}
    </>
  );
};

TransactionNotesTableRow.propTypes = {
  isLoading: PropTypes.bool,
  transactionId: PropTypes.string,
  transactionNoteData: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      note: PropTypes.string,
      operator_name: PropTypes.string
    })
  )
};

export default TransactionNotesTableRow;
