import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import MessagesTopicsFiltersBar from './components/MessagesTopicsFiltersBar';
import MessagesTopicsTableHead from './components/MessagesTopicsTableHead';
import MessagesTopicsTableRow from './components/MessagesTopicsTableRow';
import i18nContext from 'components/i18n-context';
import { DIRECTION, MENU_DIRECTION } from 'components/constants';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination/Pagination';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import './MessagesTopicsTable.scss';

const MessagesTopicsTable = ({ messagesStore }) => {
  const i18n = useContext(i18nContext);
  const location = useLocation();
  const navigate = useNavigate();

  const updateFiltersInUrl = (filters, pagination, sortColumn) => {
    const filtersParams = messagesStore.prepareFiltersParams();
    const queryParams = {
      ...filtersParams,
      page: pagination.page,
      size: pagination.size,
      sort_column: sortColumn.sort_column,
      sort_direction: sortColumn.sort_direction
    };
    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    messagesStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!messagesStore.isInitialized) {
      messagesStore.getTopicsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesStore.isInitialized]);

  useEffect(() => {
    if (messagesStore.isInitialized) {
      messagesStore.resetMessagesStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(messagesStore.filters, messagesStore.pagination, messagesStore.sortColumn);
    if (messagesStore.isInitialized) {
      messagesStore.getTopicsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    messagesStore.filters.search_text,
    messagesStore.filters.from,
    messagesStore.filters.to,
    messagesStore.filters.statuses,
    messagesStore.filters.operator,
    messagesStore.filters.unread_only,
    messagesStore.pagination.size,
    messagesStore.pagination.page,
    messagesStore.sortColumn.sort_column,
    messagesStore.sortColumn.sort_direction
  ]);

  const handleSearchText = ({ target: { name, value } }) => {
    messagesStore.setFilter(name, value);
  };

  const handleOnSortTable = (newSortBy) => {
    messagesStore.setSortData({
      sortBy: newSortBy,
      direction:
        messagesStore.sortColumn.sort_column !== newSortBy
          ? DIRECTION.ASC
          : messagesStore.sortColumn.sort_direction === DIRECTION.ASC
          ? DIRECTION.DESC
          : DIRECTION.ASC
    });
  };

  const handleClickNewMessageButton = () => {
    navigate(ROUTE_PATHS.NEW_MESSAGE);
  };

  if (!messagesStore.isInitialized) {
    return <Loader className={'application-loader'} />;
  }

  return (
    <Container className={'messages-topics-container'} header={i18n.getMessage('container.messages')}>
      <Button className={'new-message-button'} size={'medium'} type={'primary'} onClick={handleClickNewMessageButton}>
        {i18n.getMessage('messages.button.newMessage')}
      </Button>

      <MessagesTopicsFiltersBar
        filters={messagesStore.filters}
        handleSetFilters={messagesStore.setFilter}
        handleSearchText={handleSearchText}
      />

      <Pagination
        isShowPaginationSize={true}
        paginationSize={messagesStore.pagination.size}
        handleChangePaginationSize={messagesStore.setPageSize}
        isLoading={messagesStore.isLoading}
        currentPage={messagesStore.pagination.page}
        numberOfPages={messagesStore.pagination.totalPages}
        setCurrentPage={messagesStore.setPageNumber}
      />

      <Table responsive>
        <thead>
          <MessagesTopicsTableHead
            handleOnSortTable={handleOnSortTable}
            sortBy={messagesStore.sortColumn.sort_column}
            direction={messagesStore.sortColumn.sort_direction}
          />
        </thead>
        <tbody>
          {messagesStore.topics?.map((topicData, index) => (
            <MessagesTopicsTableRow
              key={index}
              type={'list'}
              isLoading={messagesStore.isLoading}
              topicData={topicData}
            />
          ))}
        </tbody>
      </Table>

      <Pagination
        isShowPaginationSize={true}
        paginationSize={messagesStore.pagination.size}
        handleChangePaginationSize={messagesStore.setPageSize}
        isLoading={messagesStore.isLoading}
        currentPage={messagesStore.pagination.page}
        numberOfPages={messagesStore.pagination.totalPages}
        setCurrentPage={messagesStore.setPageNumber}
        menuDirection={MENU_DIRECTION.UP}
      />
    </Container>
  );
};

MessagesTopicsTable.propTypes = {
  messagesStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  messagesStore: stores.messagesStore
}))(observer(MessagesTopicsTable));
