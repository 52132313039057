import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg';
import { ReactComponent as DoubleArrowLeft } from 'assets/double-arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/arrow-right.svg';
import { ReactComponent as DoubleArrowRight } from 'assets/double-arrow-right.svg';
import { PAGINATION_SIZES } from 'components/constants';
import i18nContext from 'components/i18n-context';
import { classNames } from 'uikit/utils';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './Pagination.scss';

const Pagination = ({
  isShowPaginationSize,
  paginationSize,
  handleChangePaginationSize,
  currentPage,
  numberOfPages,
  setCurrentPage,
  className,
  isLoading,
  menuDirection
}) => {
  const i18n = useContext(i18nContext);
  const paginationClasses = classNames({
    'pagination-wrapper': true,
    [className]: true
  });

  const paginationSizeOptions = PAGINATION_SIZES.map((size) => {
    return {
      key: size,
      value: size
    };
  });

  const handlePaginationSizeChange = (name, value) => {
    handleChangePaginationSize(value);
  };

  if (isLoading) {
    return (
      <div className={paginationClasses}>
        <div className={'pagination-size-dropdown'}>
          <div className={'loader-placeholder'} />
        </div>
        <div className={'pagination-pages'}>
          <div className={'loader-placeholder'} />
          <div className={'loader-placeholder'} />
          <div className={'loader-placeholder'} />
          <div className={'loader-placeholder'} />
          <div className={'loader-placeholder'} />
          <div className={'loader-placeholder'} />
        </div>
        <div className={'pagination-counter'}>
          <div className={'loader-placeholder'} />
        </div>
      </div>
    );
  }

  const pageNumbers = [];
  let startPage = Math.max(0, currentPage - 2);
  let endPage = Math.min(numberOfPages - 1, startPage + 4);
  if (endPage - startPage < 4) {
    startPage = Math.max(0, endPage - 4);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i + 1);
  }

  return (
    <div className={paginationClasses}>
      {isShowPaginationSize && (
        <InputDropDown
          isSpecificValue={true}
          className={'pagination-size-dropdown'}
          options={paginationSizeOptions}
          value={i18n.getMessage(`pagination.size`, { size: paginationSize })}
          onChange={handlePaginationSizeChange}
          menuDirection={menuDirection}
        />
      )}
      <div className={'pagination-pages'}>
        <button onClick={() => currentPage !== 0 && setCurrentPage(0)}>
          <DoubleArrowLeft />
        </button>
        <button onClick={() => currentPage !== 0 && setCurrentPage(currentPage - 1)}>
          <ArrowLeft />
        </button>
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            className={pageNumber === currentPage + 1 ? 'active' : ''}
            onClick={() => setCurrentPage(pageNumber - 1)}
          >
            {pageNumber}
          </button>
        ))}
        <button onClick={() => currentPage + 1 !== numberOfPages && setCurrentPage(currentPage + 1)}>
          <ArrowRight />
        </button>
        <button onClick={() => currentPage + 1 !== numberOfPages && setCurrentPage(numberOfPages - 1)}>
          <DoubleArrowRight />
        </button>
      </div>
      <p className={'pagination-counter'}>
        <strong>{currentPage + 1}</strong> / {numberOfPages}
      </p>
    </div>
  );
};

Pagination.propTypes = {
  isShowPaginationSize: PropTypes.bool,
  paginationSize: PropTypes.number,
  handleChangePaginationSize: PropTypes.func,
  currentPage: PropTypes.number,
  numberOfPages: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  menuDirection: PropTypes.string
};

export default Pagination;
