import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import i18nContext from 'components/i18n-context';
import { CARD_AUTHORIZATION_STATUS } from 'components/constants';
import { CustomDatePicker } from 'uikit/CustomDatePicker';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './CardAuthorisationsFiltersBar.scss';

const CardAuthorisationsFiltersBar = ({ filters, handleSearchText, handleSetFilters }) => {
  const i18n = useContext(i18nContext);

  const cardAuthorizationsStatusesOptions = Object.keys(CARD_AUTHORIZATION_STATUS).map((status) => {
    return {
      key: CARD_AUTHORIZATION_STATUS[status],
      value: i18n.getMessage('cardAuthorizationStatus.' + CARD_AUTHORIZATION_STATUS[status])
    };
  });

  return (
    <div className={'card-authorizations-filters-wrapper'}>
      <div className={'card-authorizations-date-range-filters-wrapper'}>
        <div>
          <span>{i18n.getMessage('cardAuthorizations.filter.from.label')}</span>
          <CustomDatePicker onChange={handleSetFilters} name={'from_date'} value={filters.from_date} />
        </div>
        <div>
          <span>{i18n.getMessage('cardAuthorizations.filter.to.label')}</span>
          <CustomDatePicker onChange={handleSetFilters} name={'to_date'} value={filters.to_date} />
        </div>
      </div>

      <InputDropDown
        className={'card-authorizations-filter-wrapper'}
        label={i18n.getMessage('cardAuthorizations.filter.status.label')}
        name={'statuses'}
        value={filters.statuses}
        options={cardAuthorizationsStatusesOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <Input
        className={'card-authorizations-filter-wrapper'}
        label={i18n.getMessage('cardAuthorizations.filter.search.label')}
        placeholder={i18n.getMessage('cardAuthorizations.filter.search.placeholder')}
        name={'search_text'}
        value={filters.search_text}
        onChange={handleSearchText}
        Icon={SearchIcon}
      />
    </div>
  );
};

CardAuthorisationsFiltersBar.propTypes = {
  filters: PropTypes.object,
  handleSetFilters: PropTypes.func,
  handleSearchText: PropTypes.func
};

export default CardAuthorisationsFiltersBar;
