import { makeAutoObservable, runInAction } from 'mobx';
import { DIRECTION, SYSTEM_ACCOUNT_TYPE } from 'components/constants';
import {
  createNewAccountRequest,
  disableAccountRequest,
  enableAccountRequest,
  exportAccountsList,
  exportSystemAccountsBalancesReport,
  getAccountsRequest,
  getSystemAccountsRequest,
  setAccountPrimaryRequest
} from 'services/requestAgent';

class AccountsStore {
  isLoading = false;
  isLoadingAccountsCsv = false;
  isLoadingTransitAccountsBalancesCsv = false;
  isInitialized = false;
  error = null;
  accounts = null;
  systemAccounts = null;
  pagination = {
    sortBy: null,
    page: 0,
    size: 20,
    totalPages: 0,
    totalElements: 0,
    direction: DIRECTION.ASC
  };
  filters = {
    searchText: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetAccountsStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.isLoadingAccountsCsv = false;
    this.isLoadingTransitAccountsBalancesCsv = false;
    this.error = null;
    this.accounts = null;
    this.systemAccounts = null;
    this.pagination = {
      sortBy: null,
      page: 0,
      size: 20,
      totalPages: 0,
      totalElements: 0,
      direction: DIRECTION.ASC
    };
    this.filters = {
      searchText: ''
    };
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setIsLoadingAccountsCsv = (status) => {
    this.isLoadingAccountsCsv = status;
    this.error = null;
  };

  setIsLoadingTransitAccountsBalancesCsv = (status) => {
    this.isLoadingTransitAccountsBalancesCsv = status;
    this.error = null;
  };

  setAccountsPage = (page) => {
    this.pagination.page = page;
  };

  setAccountsPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setSortData = (sortData) => {
    this.pagination.sortBy = sortData.sortBy;
    this.pagination.direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    // Copying parameters from the URL to the filters object properties
    this.filters.searchText = params.searchText || this.filters.searchText;

    // Converting pagination parameters from the URL
    this.pagination.page = parseInt(params.page) || this.pagination.page;
    this.pagination.size = parseInt(params.size) || this.pagination.size;

    // Converting sorting parameters from the URL
    this.pagination.sortBy = params.sort_column || this.pagination.sortBy;
    this.pagination.direction = params.sort_direction || this.pagination.direction;
  };

  getAccounts = async () => {
    this.setIsLoading(true);
    try {
      const {
        content: accountsList,
        number: page,
        size,
        total_elements: totalElements,
        total_pages: totalPages
      } = await getAccountsRequest(
        this.pagination.sortBy,
        this.pagination.page,
        this.pagination.size,
        this.pagination.direction,
        this.filters.searchText
      );

      runInAction(() => {
        this.isInitialized = true;
        this.accounts = accountsList;
        this.pagination = {
          ...this.pagination,
          page,
          size,
          totalElements,
          totalPages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleExportAccounts = async () => {
    this.setIsLoadingAccountsCsv(true);
    try {
      await exportAccountsList(
        this.pagination.sortBy,
        this.pagination.page,
        this.pagination.size,
        this.pagination.direction,
        {
          search_text: this.filters.searchText
        }
      );
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingAccountsCsv(false);
    }
  };

  getSystemAccounts = async () => {
    this.setIsLoading(true);
    try {
      const systemAccounts = await getSystemAccountsRequest(null, SYSTEM_ACCOUNT_TYPE.TRANSIT);

      runInAction(() => {
        this.systemAccounts = systemAccounts;
        this.isInitialized = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleExportSystemAccounts = async () => {
    this.setIsLoadingTransitAccountsBalancesCsv(true);
    try {
      await exportSystemAccountsBalancesReport(SYSTEM_ACCOUNT_TYPE.TRANSIT);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoadingTransitAccountsBalancesCsv(false);
    }
  };

  handleDisableAccount = async (accountNumber) => {
    this.setIsLoading(true);
    try {
      await disableAccountRequest(accountNumber);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleEnableAccount = async (accountNumber) => {
    this.setIsLoading(true);
    try {
      await enableAccountRequest(accountNumber);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  handleCreateNewAccount = async (customerNumber, transferProvider, data) => {
    this.setIsLoading(true);
    try {
      await createNewAccountRequest(customerNumber, transferProvider, data);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  setAccountPrimary = async (accountNumber) => {
    this.setIsLoading(true);
    try {
      await setAccountPrimaryRequest(accountNumber);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };
}

export default new AccountsStore();
