import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from 'react-bootstrap';
import moment from 'moment';
import i18nContext from 'components/i18n-context';
import { FEE_TYPE, TIMEZONE } from 'components/constants';
import Button from 'uikit/Button/Button';
import './TariffsTableRow.scss';

const TariffTableRow = ({ tariff, isLoading, handleApplyTariff, isAction }) => {
  const i18n = useContext(i18nContext);

  const getTariffsInfoBlock = (cardBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as='p' animation='glow'>
          <Placeholder className={'tariffs-tab-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{cardBlockInfo}</p>;
    }
  };

  const cronToDate = (cron) => {
    const cronParts = cron.split(' ');
    // L - 'last' for month
    return `${cronParts[3] === 'L' ? moment().endOf('month').format('DD') : cronParts[3]}`;
  };

  const cronToTime = (cron) => {
    const day = cronToDate(cron);
    const cronParts = cron.split(' ');

    const date = new Date();

    const dateTime = moment
      .tz(`${date.getFullYear()}-${date.getMonth() + 1}-${day} ${cronParts[2]}:${cronParts[1]}`, 'YYYY-MM-D h:m', 'UTC')
      .tz(TIMEZONE);
    return `${dateTime.format('HH')}:${dateTime.format('mm')}`;
  };

  return (
    <tr>
      <td className={'tariffs-table-cell'}>{getTariffsInfoBlock(tariff.type.replaceAll('_', ' '))}</td>
      <td className={'tariffs-table-cell'}>
        {tariff.value.fee_type === FEE_TYPE.FIXED &&
          getTariffsInfoBlock(`${tariff.value.value || '0.00'} ${tariff.value.currency}`)}
        {tariff.value.fee_type === FEE_TYPE.SCHEDULED && (
          <>
            {getTariffsInfoBlock(`${tariff.value.value || '0.00'} ${tariff.value.currency}`)}
            {tariff.value.cron && (
              <>
                {getTariffsInfoBlock(i18n.getMessage('tariffsTab.cron.day', { day: cronToDate(tariff.value.cron) }))}
                {getTariffsInfoBlock(i18n.getMessage('tariffsTab.cron.time', { time: cronToTime(tariff.value.cron) }))}
              </>
            )}
          </>
        )}
      </td>
      <td className={'tariffs-table-cell'}>
        <Button
          type={'outline'}
          size={'small'}
          className={'tariffs-container-actions-button'}
          onClick={handleApplyTariff}
        >
          {i18n.getMessage('tariffsTab.buttons.apply')}
        </Button>
      </td>
    </tr>
  );
};

TariffTableRow.prototype = {
  tariff: PropTypes.object,
  isLoading: PropTypes.bool,
  handleApplyTariff: PropTypes.func
};

export default TariffTableRow;
