import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import { CUSTOMER_PERMISSIONS } from 'components/constants';
import Button from 'uikit/Button/Button';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import '../RepresentativesTab.scss';

export const PermissionScheme = ({
  closePopUp,
  email,
  error,
  clearError,
  submitPermissions,
  permissions,
  representativeForUpdate
}) => {
  const i18n = useContext(i18nContext);
  const [currentPermissions, setCurrentPermissions] = useState(
    representativeForUpdate?.permissions || [CUSTOMER_PERMISSIONS.READ_ACCOUNT]
  );

  const onConfirmClick = async () => {
    await submitPermissions(currentPermissions);
  };

  const onCheckBoxChange = (key) => {
    if (!currentPermissions.includes(key)) setCurrentPermissions((prev) => [...prev, key]);
    else {
      setCurrentPermissions(currentPermissions.filter((perm) => perm !== key));
    }
  };

  const isSelectAll = !permissions.filter((permission) => {
    if (permission !== CUSTOMER_PERMISSIONS.READ_MESSAGES) {
      return !currentPermissions.includes(permission);
    }
  }).length;

  const availablePermissions = permissions.filter((permission) => permission !== CUSTOMER_PERMISSIONS.READ_MESSAGES);

  const renderButtonText = () => {
    return i18n.getMessage(representativeForUpdate ? 'representatives.label.change' : 'representatives.label.add');
  };

  return (
    <div className='scheme-wrapper permission'>
      <div className='representatives-add-header-wrapper'>
        <h2 className='representatives-popUp-header'>{i18n.getMessage('representatives.header.permission')}</h2>
        <CloseIcon className='representatives-icon' onClick={closePopUp} />
      </div>
      <p className={'representatives-email'}>{email || 'Name@example.com'}</p>
      <CheckBox
        className='representatives-checkbox full-checkbox'
        isActive={isSelectAll}
        showCheckBox={true}
        onChange={() => setCurrentPermissions(isSelectAll ? [CUSTOMER_PERMISSIONS.READ_ACCOUNT] : availablePermissions)}
        value={i18n.getMessage('representatives.label.fullAccess')}
      />
      <div className='permission-checkboxes-wrapper'>
        {Object.keys(CUSTOMER_PERMISSIONS).map(
          (permission) =>
            permission !== CUSTOMER_PERMISSIONS.READ_MESSAGES && (
              <CheckBox
                className='representatives-checkbox'
                key={`representatives-permission-${permission}-id`}
                isActive={currentPermissions?.includes(permission)}
                onChange={() => onCheckBoxChange(permission)}
                disabled={permission === CUSTOMER_PERMISSIONS.READ_ACCOUNT}
                showCheckBox={true}
                value={i18n.getMessage(`representatives.permission.${permission}`)}
              />
            )
        )}
      </div>
      <Button size='large' fullWidth={true} onClick={onConfirmClick}>
        {renderButtonText()}
      </Button>
    </div>
  );
};

PermissionScheme.propTypes = {
  closePopUp: PropTypes.func.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  clearError: PropTypes.func,
  submitPermissions: PropTypes.func,
  error: PropTypes.any,
  representativeForUpdate: PropTypes.object,
  permissions: PropTypes.array
};
