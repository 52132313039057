import { Outlet, useLocation } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import BackButton from 'components/BackButton';
import { ROUTE_PATHS } from 'routes/constants';

const Transactions = ({ transactionsStore }) => {
  const location = useLocation();

  return (
    <section className={'right-section'}>
      {location.pathname !== ROUTE_PATHS.TRANSACTIONS && <BackButton />}
      <Outlet />
    </section>
  );
};

Transactions.propTypes = {
  transactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  transactionsStore: stores.transactionsStore
}))(observer(Transactions));
