import { makeAutoObservable, runInAction } from 'mobx';
import { getUserRolesRequest, loginUser } from 'services/requestAgent';
import { getCachedRoles, KEY_ROLES, processRolesArray } from 'services/roles';

class AuthStore {
  isLoading = false;
  isLoginSuccess = false;
  userRoles = [];
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.isLoading = false;
    this.isLoginSuccess = false;
    this.userRoles = [];
    this.error = null;
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setIsLoginSuccess = (state) => (this.isLoginSuccess = state);

  sendAuthorizationData = async (data) => {
    this.setIsLoading(true);
    try {
      await loginUser(data);

      runInAction(() => {
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  };

  getUserRoles = async (force = false) => {
    this.setIsLoading(true);
    try {
      const rolesFromCache = getCachedRoles();
      let roles = [];

      if (!rolesFromCache || force) {
        const response = await getUserRolesRequest();
        roles = Object.values(response).map(({ authority }) => authority);
        localStorage.setItem(KEY_ROLES, roles.join(','));
      }
      processRolesArray(roles.length > 0 ? roles : rolesFromCache);

      runInAction(() => {
        this.isLoginSuccess = true;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  };
}

export default new AuthStore();
