import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import RichTextEditor from 'react-rte/lib/RichTextEditor';
import { NotesTabHeadRow } from './NotesTabHeadRow';
import NotesTabTableRow from './NotesTabTableRow';
import { LATIN_LETTERS_AND_DIGITS_AND_SYMBOLS_REG_EXP, MAX_MESSAGE_LENGTH } from 'components/constants';
import Loader from 'components/Loader';
import i18nContext from 'components/i18n-context';
import { convertHtmlToString, convertMessageToHtmlAndTrimSpaces } from 'services/utils';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import './NotesTab.scss';

const NotesTab = ({ isLoading, notes, transactionNumber, handleLoadNotes, handleAddNote, handleDeleteNote }) => {
  const [noteValue, setNoteValue] = useState(RichTextEditor.createEmptyValue());
  const i18n = useContext(i18nContext);

  useEffect(() => {
    handleLoadNotes();
  }, []);

  const addNote = () => {
    if (!new RegExp(LATIN_LETTERS_AND_DIGITS_AND_SYMBOLS_REG_EXP).test(convertHtmlToString(noteValue))) {
      //TODO: Error notification logic
    } else {
      const convertedNote = convertMessageToHtmlAndTrimSpaces(noteValue);
      handleAddNote(transactionNumber, convertedNote).then(() => {
        setNoteValue(RichTextEditor.createEmptyValue());
      });
    }
  };

  if (!notes) {
    return <Loader className={'application-loader customer-notes-tab-loader'} />;
  }

  return (
    <div className={'customer-notes-container'}>
      <Table responsive>
        <thead>
          <NotesTabHeadRow />
        </thead>
        <tbody>
          {notes.map((note) => (
            <NotesTabTableRow key={note.id} isLoading={false} noteData={note} handleRemoveNote={handleDeleteNote} />
          ))}
        </tbody>
      </Table>

      <Input
        className={'customer-notes-container-input'}
        type={'text-editor'}
        name={'note'}
        isDisabled={false}
        value={noteValue}
        onChange={setNoteValue}
        max={MAX_MESSAGE_LENGTH}
        rows={5}
        /* eslint-disable-next-line max-len */
        subText={i18n.getMessage('customerNotes.input.note.symbolsLeft', {
          symbolsLeft: MAX_MESSAGE_LENGTH - convertHtmlToString(noteValue).length
        })}
      />

      <div className={'customer-notes-container-add-note'}>
        <Button
          className={'customer-notes-container-add=note-button'}
          type={'primary'}
          size={'small'}
          onClick={addNote}
          isDisabled={!noteValue || isLoading}
        >
          {isLoading ? <Loader /> : i18n.getMessage('customerNotes.button.addNote')}
        </Button>
      </div>
    </div>
  );
};

NotesTab.propTypes = {
  isLoading: PropTypes.bool,
  notes: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
  transactionNumber: PropTypes.string,
  handleLoadNotes: PropTypes.func,
  handleAddNote: PropTypes.func,
  handleDeleteNote: PropTypes.func
};

export default NotesTab;
