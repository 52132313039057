import PropTypes from 'prop-types';
import { Placeholder } from 'react-bootstrap';
import { convertUTCtoGB, parseGBDateToString } from 'services/utils';
import './MonitoringTabTableRow.scss';

export const MonitoringTabTableRow = ({
  isLoading,
  monitoringData,
  handleOpenTransactionDetails,
  downloadDocumentById
}) => {
  const getMonitoringTabInfoBlock = (monitoringBlockInfo, callBack) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'monitoring-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (callBack) {
      return (
        // eslint-disable-next-line
        <p className={'monitoring-table-cell-info-link'} onClick={callBack}>
          {monitoringBlockInfo}
        </p>
      );
    } else {
      return <p>{monitoringBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'monitoring-table-cell'}>
        <div className={'monitoring-table-cell-info'}>
          {getMonitoringTabInfoBlock(monitoringData.transaction_number, () =>
            handleOpenTransactionDetails(monitoringData.transaction_number)
          )}
          <div className={'monitoring-table-cell-info-date'}>
            {getMonitoringTabInfoBlock(parseGBDateToString(convertUTCtoGB(monitoringData.transaction_updated_at)))}
          </div>
        </div>
      </td>
      <td className={'monitoring-table-cell'}>{getMonitoringTabInfoBlock(monitoringData.counterparty_name)}</td>
      <td className={'monitoring-table-cell'}>
        <div className={'monitoring-table-cell-info'}>
          {getMonitoringTabInfoBlock(monitoringData.document_name, () =>
            downloadDocumentById(monitoringData.document_uid)
          )}
        </div>
      </td>
    </tr>
  );
};

MonitoringTabTableRow.propTypes = {
  isLoading: PropTypes.bool,
  handleOpenTransactionDetails: PropTypes.func,
  downloadDocumentById: PropTypes.func,
  monitoringData: PropTypes.shape({
    transaction_number: PropTypes.string,
    transaction_updated_at: PropTypes.string,
    counterparty_name: PropTypes.string,
    document_name: PropTypes.string,
    document_uid: PropTypes.string
  })
};
