import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from 'react-bootstrap';
import { convertUTCtoGB, parseGBDateToString } from 'services/utils';
import i18nContext from 'components/i18n-context';
import './LogsTabTableRow.scss';

export const LogsTabTableRow = ({ isLoading, logsTabData }) => {
  const i18n = useContext(i18nContext);

  const formatDateTime = (logDate) => {
    const date = parseGBDateToString(convertUTCtoGB(logDate));

    return <i className={'logsTab-table-cell-info-date'}>{date}</i>;
  };

  const getLogsTabInfoBlock = (logsTabBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as='p' animation='glow'>
          <Placeholder className={'logs-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{logsTabBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'logs-table-cell'}>
        <div className={'logs-table-cell-info'}>
          {getLogsTabInfoBlock(logsTabData.operator.name)}
          <div className={'logs-table-cell-info-date'}>
            {getLogsTabInfoBlock(formatDateTime(logsTabData.created_at))}
          </div>
        </div>
      </td>
      <td className={'logs-table-cell'}>
        {getLogsTabInfoBlock(i18n.getMessage(`customerLogs.action.${logsTabData.changed_field}`))}
      </td>
      <td className={'logs-table-cell'}>
        {`${logsTabData.old_value ? logsTabData.old_value : 'Not Set'} -> ${logsTabData.new_value}`}
      </td>
    </tr>
  );
};

LogsTabTableRow.propTypes = {
  isLoading: PropTypes.bool,
  logsTabData: PropTypes.shape({
    account_number: PropTypes.string,
    changed_field: PropTypes.string,
    new_value: PropTypes.string,
    old_value: PropTypes.string,
    created_at: PropTypes.string,
    operator: PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string
    })
  })
};
