import { makeAutoObservable, runInAction } from 'mobx';
import { DIRECTION } from 'components/constants';
import {
  chargePaymentObligationRequest,
  getPaymentObligationsListRequest,
  releasePaymentObligationRequest
} from 'services/requestAgent';
import { convertDate, convertToFullDate } from 'services/utils';

class PaymentObligationsStore {
  isInitialized = false;
  isLoading = false;
  error = null;
  paymentObligationsList = [];
  totalElements = null;
  sortColumn = {
    sort_column: 'created_at',
    sort_direction: DIRECTION.DESC
  };
  pagination = {
    size: 20,
    page: 0,
    totalPages: null
  };
  filters = {
    search_text: null,
    from_date: null,
    to_date: null,
    statuses: [],
    currencies: []
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetPaymentObligationsStore = () => {
    this.isInitialized = false;
    this.isLoading = false;
    this.error = null;
    this.paymentObligationsList = [];
    this.totalElements = null;
    this.sortColumn = {
      sort_column: 'created_at',
      sort_direction: DIRECTION.DESC
    };
    this.pagination = {
      size: 20,
      page: 0,
      totalPages: null
    };
    this.filters = {
      search_text: null,
      from_date: null,
      to_date: null,
      statuses: [],
      currencies: []
    };
  };

  setIsLoading = (status) => {
    this.isLoading = status;
    this.error = null;
  };

  setPageNumber = (page) => {
    this.pagination.page = page;
  };

  setPageSize = (size) => {
    this.pagination.size = size;
  };

  setFilter = (fieldName, value) => {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  };

  setSortData = (sortData) => {
    this.sortColumn.sort_column = sortData.sortBy;
    this.sortColumn.sort_direction = sortData.direction;
  };

  setFiltersFromUrl = (params) => {
    const filters = { ...this.filters };
    const pagination = { ...this.pagination };

    // Mapping URL filter parameters to corresponding properties in the 'filters' object
    const filterParamsMapping = {
      search_text: 'search_text',
      from_date: 'from_date',
      to_date: 'to_date',
      statuses: 'statuses',
      currencies: 'currencies'
    };

    // Iterating over each URL parameter and assigning its values to 'filters'
    for (const param in params) {
      if (param in filterParamsMapping) {
        let value = params[param];

        // Convert `from_date` and `to_date` if they exist
        if (param === 'from_date' || param === 'to_date') {
          value = convertToFullDate(value);
        }

        // Handle only array values as arrays, keep others as their original types
        if (Array.isArray(value)) {
          filters[filterParamsMapping[param]] = value;
        } else {
          filters[filterParamsMapping[param]] = value;
        }
      }
    }

    // Assigning pagination parameters from the URL
    pagination.page = parseInt(params.page) || pagination.page;
    pagination.size = parseInt(params.size) || pagination.size;

    // Assigning sorting parameters from the URL
    this.sortColumn.sort_column = params.sort_column || this.sortColumn.sort_column;
    this.sortColumn.sort_direction = params.sort_direction || this.sortColumn.sort_direction;

    // Updating the state of filters and pagination
    this.filters = filters;
    this.pagination = pagination;
  };

  prepareFiltersParams = () => {
    const params = { ...this.filters };

    if (this.filters.from_date) {
      params.from_date = convertDate(this.filters.from_date);
    } else {
      delete params.from_date;
    }

    if (this.filters.to_date) {
      params.to_date = convertDate(this.filters.to_date);
    } else {
      delete params.to_date;
    }

    return params;
  };

  getPaymentObligationsList = async () => {
    this.setIsLoading(true);
    try {
      const filtersParams = this.prepareFiltersParams();

      const res = await getPaymentObligationsListRequest(
        this.pagination,
        this.sortColumn.sort_column,
        this.sortColumn.sort_direction,
        filtersParams.search_text,
        filtersParams
      );

      runInAction(() => {
        this.isInitialized = true;
        this.paymentObligationsList = res.content;
        this.totalElements = res.total_elements;
        this.pagination = {
          size: res.size,
          page: res.number,
          totalPages: res.total_pages
        };
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  releasePaymentObligation = async (paymentObligationId) => {
    this.setIsLoading(true);
    try {
      const paymentObligationDetails = await releasePaymentObligationRequest(paymentObligationId);

      const updatedPaymentObligationsList = this.paymentObligationsList.map((obligation) =>
        obligation.obligation_id === paymentObligationId ? paymentObligationDetails : obligation
      );

      runInAction(() => {
        this.paymentObligationsList = updatedPaymentObligationsList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };

  chargePaymentObligation = async (paymentObligationId) => {
    this.setIsLoading(true);
    try {
      const paymentObligationDetails = await chargePaymentObligationRequest(paymentObligationId);

      const updatedPaymentObligationsList = this.paymentObligationsList.map((obligation) =>
        obligation.obligation_id === paymentObligationId ? paymentObligationDetails : obligation
      );

      runInAction(() => {
        this.paymentObligationsList = updatedPaymentObligationsList;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      this.setIsLoading(false);
    }
  };
}

export default new PaymentObligationsStore();
