import React, { useContext } from 'react';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { convertUTCtoGB, parseGBDateToString } from 'services/utils';
import './LimitDetailsTableRow.scss';
import { hasRole, LIMIT_GROUPS_UPDATE } from 'services/roles';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import { LimitActionsInputControl } from '../inpunts/LimitActionsInputControl';
import { LimitBehaviourInputControl } from '../inpunts/LimitBehaviourInputControl';

const FIRST_GROUP_OF_LIMITS_TYPE = ['AMOUNT', 'COUNT'];
const SECOND_GROUP_OF_LIMITS_TYPE = ['KEYWORDS', 'FIRST_6_MONTH'];

export const LimitDetailsTableRow = ({ isLoading, limitDetailsData }) => {
  const i18n = useContext(i18nContext);
  const [enabled, setEnabled] = React.useState(limitDetailsData.enabled);

  const getLimitInfoBlock = (limitBlockInfo) => {
    if (isLoading) {
      return (
        <Placeholder as='p' animation='glow'>
          <Placeholder className={'limit-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else {
      return <p>{limitBlockInfo}</p>;
    }
  };

  const formatDate = (limitDate) => {
    return parseGBDateToString(convertUTCtoGB(limitDate));
  };

  const handleDelete = (limit) => () => {
    // TODO: Implement delete limit
  };

  const handleLimitChange = (limitType) => (valueType, value) => {
    // TODO: Implement handle limit change
  };

  return (
    <tr key={limitDetailsData._limitType}>
      <td className={'limits-table-cell'}>
        {getLimitInfoBlock(i18n.getMessage(`limit.label.${limitDetailsData._limitType}`))}
      </td>
      <td>
        {(FIRST_GROUP_OF_LIMITS_TYPE.includes(limitDetailsData.limit_type) && (
          <LimitActionsInputControl
            onChange={handleLimitChange(limitDetailsData._limitType)}
            currency={limitDetailsData.currency}
            hold={limitDetailsData.hold}
            notification={limitDetailsData.notification}
            reject={limitDetailsData.reject}
            editable={!hasRole(LIMIT_GROUPS_UPDATE)}
            type={limitDetailsData.type}
            limitType={limitDetailsData.limit_type}
          />
        )) ||
          (SECOND_GROUP_OF_LIMITS_TYPE.includes(limitDetailsData.limit_type) && (
            <LimitBehaviourInputControl
              onChange={handleLimitChange(limitDetailsData._limitType)}
              behaviour={limitDetailsData.behavior}
              editable={!!hasRole(LIMIT_GROUPS_UPDATE)}
            />
          )) ||
          null}
      </td>
      <td className={'limits-table-cell'}>
        {limitDetailsData.account_number ? (
          <i>{i18n.getMessage('limits.details.table.row.isIndividual')}</i>
        ) : (
          limitDetailsData.group
        )}
      </td>
      <td className={'limits-table-cell'}>{getLimitInfoBlock(formatDate(limitDetailsData.created_at))}</td>
      <td className={'limits-table-cell'}>{getLimitInfoBlock(formatDate(limitDetailsData.updated_at))}</td>
      <td className={'limits-table-cell'}>
        <div className={'limits-table-cell-action'}>
          <div className={'limits-table-cell-action-enabled'}>
            <CheckBox
              className={'form-newLimitType-status-wrapper-checkbox'}
              showCheckBox={true}
              isActive={enabled}
              onChange={() => {
                setEnabled((prevState) => !prevState);
              }}
            />
            <span>{i18n.getMessage('limits.details.table.row.enabled')}</span>
          </div>
          {limitDetailsData._limitType !== 'KEYWORDS' && limitDetailsData.account_number && (
            <button className='btn btn-danger' onClick={handleDelete(limitDetailsData)}>
              {i18n.getMessage('limits.details.table.row.delete')}
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

LimitDetailsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  limitDetailsData: PropTypes.shape({
    limit_type: PropTypes.string,
    _limitType: PropTypes.string,
    account_number: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    group: PropTypes.string,
    currency: PropTypes.string,
    notification: PropTypes.string,
    hold: PropTypes.string,
    reject: PropTypes.string,
    type: PropTypes.string,
    behavior: PropTypes.string,
    enabled: PropTypes.bool
  })
};
