import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { ROUTE_PATHS } from 'routes/constants';
import './AccountsTableRow.scss';
import { formatMoney, getCustomerLink } from '../../../../../../services/utils';

export const AccountsTableRow = ({ isLoading, accountData }) => {
  const i18n = useContext(i18nContext);
  const isMultiAccount = !!accountData.multi_wallet_number;

  const getAccountInfoBlock = (accountBlockInfo, isCustomerNumber = false) => {
    if (isLoading) {
      return (
        <Placeholder as='p' animation='glow'>
          <Placeholder className={'accounts-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isCustomerNumber) {
      return (
        <NavLink
          className={'customer-id-text'}
          to={getCustomerLink(accountData.account_number, accountData.status, accountData.account_type)}
        >
          {accountBlockInfo}
        </NavLink>
      );
    } else {
      return <p>{accountBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'accounts-table-cell'}>{getAccountInfoBlock(accountData.wallet_number)}</td>
      <td className={'accounts-table-cell'}>
        {getAccountInfoBlock(
          isMultiAccount
            ? `${i18n.getMessage('accounts.table.multiCurrencyAccount.yes')} (${accountData.multi_wallet_number})`
            : i18n.getMessage('accounts.table.multiCurrencyAccount.no')
        )}
      </td>
      <td className={'accounts-table-cell'}>{getAccountInfoBlock(accountData.account_number, true)}</td>
      <td className={'accounts-table-cell'}>
        {getAccountInfoBlock(isMultiAccount ? accountData.multi_iban : accountData.iban)}
      </td>
      <td className={'accounts-table-cell'}>{getAccountInfoBlock(accountData.currency)}</td>
      <td className={'accounts-table-cell'}>
        {getAccountInfoBlock(formatMoney(accountData.total, accountData.currency))}
      </td>
      <td className={'accounts-table-cell'}>
        {getAccountInfoBlock(i18n.getMessage(`accountStatus.${accountData.status}`))}
      </td>
      <td className={'accounts-table-cell'}>
        {getAccountInfoBlock(i18n.getMessage(`paymentProvider.${accountData.transfer_provider}`))}
      </td>
      <td className={'accounts-table-cell'}>{getAccountInfoBlock(accountData.external_reference)}</td>
    </tr>
  );
};

AccountsTableRow.propTypes = {
  isLoading: PropTypes.bool,
  accountData: PropTypes.shape({
    status: PropTypes.string,
    currency: PropTypes.string,
    total: PropTypes.number,
    available: PropTypes.number,
    minimal: PropTypes.number,
    frozen: PropTypes.number,
    wallet_number: PropTypes.string,
    account_number: PropTypes.string,
    account_type: PropTypes.string,
    iban: PropTypes.string,
    on_hold: PropTypes.number,
    multi_wallet_number: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    multi_iban: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    type: PropTypes.string,
    address: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    token_contract: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    transfer_provider: PropTypes.string,
    wallet_account_number: PropTypes.string,
    bic: PropTypes.string,
    sort_code: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    bank_address: PropTypes.string,
    bank_name: PropTypes.string,
    bank_country: PropTypes.string,
    payment_methods: PropTypes.arrayOf(PropTypes.string),
    external_reference: PropTypes.string,
    created_at: PropTypes.string
  })
};
