import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import i18nContext from 'components/i18n-context';
import { CUSTOMER_STATUSES, CUSTOMER_TYPE, KYC_STATUSES, RISK_PROFILE } from 'components/constants';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './CustomersFiltersBar.scss';

const CustomersFiltersBar = ({
  isShowActiveCustomers,
  filters,
  limits,
  tariffs,
  handleSearchText,
  handleSetFilters
}) => {
  const i18n = useContext(i18nContext);

  const customerTypeOptions = Object.keys(CUSTOMER_TYPE).map((status) => {
    return {
      key: CUSTOMER_TYPE[status],
      value: i18n.getMessage('customers.type.' + CUSTOMER_TYPE[status])
    };
  });

  const customerStatusOptions = Object.keys(CUSTOMER_STATUSES).map((status) => {
    return {
      key: CUSTOMER_STATUSES[status],
      value: i18n.getMessage('customers.status.' + CUSTOMER_STATUSES[status])
    };
  });

  const kycStatusOptions = (
    isShowActiveCustomers
      ? Object.keys(KYC_STATUSES).filter((s) => s === KYC_STATUSES.VERIFIED)
      : Object.keys(KYC_STATUSES).filter((s) => s !== KYC_STATUSES.VERIFIED)
  ).map((status) => ({
    key: KYC_STATUSES[status],
    value: i18n.getMessage('kyc.status.' + KYC_STATUSES[status])
  }));

  const tariffGroupsOptions = tariffs.map((status) => {
    return {
      key: status,
      value: status
    };
  });

  const limitGroupsOptions = limits.map((status) => {
    return {
      key: status,
      value: status
    };
  });

  const riskProfileOptions = Object.keys(RISK_PROFILE).map((status) => {
    return {
      key: RISK_PROFILE[status],
      value: i18n.getMessage('customers.riskProfile.' + RISK_PROFILE[status])
    };
  });

  return (
    <div className={'customers-filters-wrapper'}>
      <InputDropDown
        className={'filter-wrapper'}
        name={'types'}
        label={i18n.getMessage('customers.filter.customerType.label')}
        value={filters.types}
        options={customerTypeOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'filter-wrapper'}
        label={i18n.getMessage('customers.filter.customerStatus.label')}
        name={'account_statuses'}
        value={filters.account_statuses}
        options={customerStatusOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'filter-wrapper'}
        label={i18n.getMessage('customers.filter.kycStatus.label')}
        name={'kyc_statuses'}
        value={filters.kyc_statuses}
        options={kycStatusOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'filter-wrapper'}
        label={i18n.getMessage('customers.filter.tariffGroup.label')}
        name={'tariff_groups'}
        value={filters.tariff_groups}
        options={tariffGroupsOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'filter-wrapper'}
        label={i18n.getMessage('customers.filter.limitGroup.label')}
        name={'limit_groups'}
        value={filters.limit_groups}
        options={limitGroupsOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <InputDropDown
        className={'filter-wrapper'}
        label={i18n.getMessage('customers.filter.riskProfile.label')}
        name={'risk_profiles'}
        value={filters.risk_profiles}
        options={riskProfileOptions}
        onChange={handleSetFilters}
        isMulti
      />
      <Input
        className={'filter-wrapper'}
        label={i18n.getMessage('customers.filter.search.label')}
        placeholder={i18n.getMessage('customers.filter.search.placeholder')}
        name={'search_text'}
        value={filters.search_text}
        onChange={handleSearchText}
        Icon={SearchIcon}
      />
    </div>
  );
};

CustomersFiltersBar.propTypes = {
  isShowActiveCustomers: PropTypes.bool,
  tariffs: PropTypes.array,
  limits: PropTypes.array,
  filters: PropTypes.object,
  handleSetFilters: PropTypes.func,
  handleSearchText: PropTypes.func
};

export default CustomersFiltersBar;
